import React from 'react'
import {Navbar, NavItem,Icon} from 'react-materialize'
import '../styles/navbar.css'

const NavbarUA= (props) => {
  return(
    <Navbar   className="col-ipn " >
      <div  className=" navbarF">
        <NavItem  href="/homeUA" >
          Inicio
          <Icon left>
            home
          </Icon>
        </NavItem>
        <NavItem   href="/listUA" >
          IPN
          <Icon left>
            group
          </Icon>
        </NavItem>
        <NavItem    onClick={props.logOut} >
          Cerrar Sesión
          <Icon left>
            exit_to_app
          </Icon>
        </NavItem>
      </div>
    </Navbar>
  )
};

export default NavbarUA
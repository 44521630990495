import React,{Component} from 'react'
import NavbarUA from "./NavabarUA";
import axios from "axios";
import {Redirect} from "react-router-dom";


class HomeUA extends Component{

  state={
    session:false, token : localStorage.getItem('token')

  };

  sessionActive=()=>{
    let {token}= this.state;
    const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

    const newHead = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(newUrl, newHead)
      .then(res => {
        console.log("Token activo home")
      })
      .catch((err)=>{
        console.log("la sesion acabo");
        this.setState({session:true})
      })
  };

  update=()=>{
      const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/actualizar/solicitud";
      //solicitud
      let Datos_2={
          "alumno_id": 449,
          "cat_estatus_solicitud_id": 387,
          "convocatoria_id": 2,
          "fase1_solicitud": true,
          "fase2_evaluacion": true,
          "fase3_postulacion": false,
          "fase4_aceptado": false,
          "fase5_fin_estancia": false,
          "fase6_liberado": false,
          "observaciones": "Segunda Fase"
      };
      let Datos={
          "instituto_1": 201,
          "instituto_2": 327,
          "instituto_3": 229,
          "no_opcion": 3,
          "solicitud_id": 1123
      };
          let token = localStorage.getItem('token');
          let headers = {
              headers: {
                  "Accept": "application/json",
                  "HR-TOKEN-APPID": 2,
                  "HR-APP-VERSION": "1.0.0",
                  "HR-APP-TYPE": "WEB",
                  "HR-TOKEN-SESSION": token,
                  "Content-Type": "application/json",
              }
          };
          axios.put(url, Datos_2, headers)
              .then((res)=>{
                  console.log('Actualización',res);
              })
              .catch(err=>console.log('Error-Actualizar',err));

  };

  componentDidMount() {
    this.sessionActive();
    //this.update();
  }

  LogOut = () => {

    let token=localStorage.getItem('token');
    console.log('entró al logout',token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(url, head)
      .then(res => {
        console.log('res de logout', res);


        localStorage.removeItem('profile');
        localStorage.removeItem('userName');

        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('alumno_id');
        this.props.history.push("/login");
      })

      .catch(err=>console.log(err))

  };

  render() {
    let {session} =this.state;
    return(
      <>
        <NavbarUA
          logOut={this.LogOut}
        />
        {/*verifica que la sesión esta activa y si no te redirecciona al Home*/}
        {
          session===true?
            <>
              <Redirect to="/login"/>
            </>
            :
            <>
              <h1>Bienvenido UA</h1>
            </>
        }
      </>
    )
  }
}

export default HomeUA
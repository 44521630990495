import React, { Component } from 'react'
import axios from 'axios'
import NavbarCCA from './NavbarCCA';
import {Redirect} from "react-router-dom";
import {Card, Table, Input, Button, Modal} from 'react-materialize'

class ValidateRegister extends Component {

    state={
        boleta:localStorage.getItem('bolStudent'), image: [],
        session:false,token : localStorage.getItem('token'),
        form:{
            ValCe: false,
            ValCi: false,
            ValSo: false,
            ValCV: false,
            ValId: false,
            ValCer: false,
            ValCar: false,
            ValOff:false,
            ValCons: false,
            ValBol: false,
            ValIng: false,
            ValSeg: false,
            ValTer: false,
            ValCuar: false

        }
    };

    sessionActive=()=>{
        let {token}= this.state;
        const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

        const newHead = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };

        axios.get(newUrl, newHead)
            .then(res => {
                console.log("Token activo table")
            })
            .catch((err)=>{
                console.log("la sesion acabo");
                this.setState({session:true})
            })
    };

    LogOut = () => {

        let token=localStorage.getItem('token');
        console.log('entró al logout',token);

        const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                localStorage.removeItem('profile');
                localStorage.removeItem('userName');

                localStorage.removeItem('userId');
                localStorage.removeItem('token');
                localStorage.removeItem('alumno_id');

                this.props.history.push("/login");
            })

            .catch(err=>console.log(err))

    };
    checkDocCartaE = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CartaEspanol/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Carta_Español.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocCartaI = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CartaIngles/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Carta_Ingles.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocSolicitudP = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/SolicitudMovilidad/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Solicitud_Movilidad.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocCV = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CurriculumVitae/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Curriculum_Vitae.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));
    };
    checkDocIdentOf = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/IdentificacionOficial/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Identificacion_Oficial.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocCertMed = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CertificadoMedico/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Certificado_Medico.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));
    };

    checkDocCartMed = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CarnetMedico/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Carnet_Medico.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocOficio = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/OficioPostulacion/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Oficio Postulación.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocConst = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/ConstanciaEstudios/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Constancia de Cred_Prom.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocBoleta = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/BoletaGlobal/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Boleta.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocIngles = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/DominioIngles/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "DominioIngles.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
               // downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocSegundo = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/SegundoIdioma/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "SegundoIdioma.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocTercer = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/TercerIdioma/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "TercerIdioma.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocCuarto = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CuartoIdioma/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "CuartoIdioma.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    /*el json que se usa para agregar el nivel ,el nombre del idioma , si es valiodo o no y las notas es el mismo un ejemplo de json es
    {
    "alumno_id": 449
    "carta_exposicion_motivos_espanol_nota": "por que yolo",
    "carta_exposicion_motivos_espanol_valida": true
    }

    los valores del json deben hacer match con los nombres que se desan modifcar de la base de datos
    el endpoint es
    /IPN-Central-Route/archivo/movilidadDocumentos/updateMetaD
    ata*/
    handleForValidation =(e)=> {

        let {form} =this.state;
        let {value,name}=e.target;

        form[name]=value;
        console.log('form-------',form);
        this.setState({form});
    };

    validarDocs=(e)=>{
        e.preventDefault();
        console.log('form',this.state.form);
        let alumno_id = localStorage.getItem('bolStudent');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/movilidadDocumentos/updateMetaData`;
        let token = localStorage.getItem('token');
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        const datos={
            "alumno_id": alumno_id,
            "carta_exposicion_motivos_espanol_nota": this.state.form.NotaCe,
            "carta_exposicion_motivos_espanol_valida": this.state.form.ValCe,
            "carta_exposicion_motivos_ingles_nota": this.state.form.NotaCi,
            "carta_exposicion_motivos_ingles_valida": this.state.form.ValCi,
            "solicitud_programa_movilidad_academica_nota": this.state.form.NotaSo,
            "solicitud_programa_movilidad_academica_valida": this.state.form.ValSo,
            "curriculum_vitae_nota": this.state.form.NotaCV,
            "curriculum_vitae_valido": this.state.form.ValCV,
            "identificacion_oficial_nota": this.state.form.NotaId,
            "identificacion_oficial_valida":this.state.form.ValId,
            "certificado_medico_nota": this.state.form.NotaCer,
            "certificado_medico_valido": this.state.form.ValCer,
            "cartilla_carnet_seguro_medico_nota":this.state.form.NotaCar,
            "cartilla_carnet_seguro_medico_valida":this.state.form.ValCar,
            "oficio_postulacion_nota":this.state.form.NotaOff,
            "oficio_postulacion_valido":this.state.form.ValOff,
            "comprobante_dominio_ingles_nota": this.state.form.NotaIng,
            "comprobante_dominio_ingles_valido": this.state.form.ValIng,
            "comprobante_segundo_idioma_nota":this.state.form.NotaSeg,
            "comprobante_segundo_idioma_valido": this.state.form.ValSeg,
            "comprobante_tercer_idioma_nota": this.state.form.NotaTer,
            "comprobante_tercer_idioma_valido": this.state.form.ValTer,
            "comprobante_cuarto_idioma_nota": this.state.form.NotaCuar,
            "comprobante_cuarto_idioma_valido": this.state.form.ValCuar

        };
        console.log(token);
        axios.post(url, datos, head)
            .then((res)=>{
                console.log('Validar Docs',res);
                this.setState({infoDoc:res})
            })
            .catch(err=>console.log('error al Validar',err))
    };


    noificareMail=(e)=>{
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        const url2 = "http://148.204.63.213:80/IPN-Central-Route/correo/enviar ";

        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "Web",
                "Content-Type": "application/json",
            }
        };
        let data2 = {
            "correo_destino": "ing_franciscoislas@outlook.com ",
            "correo_asunto": "Validación de Docuemntos",
            "correo_contenido": "Tus documentos estan sien do validados, debes entrar a tu cuenta del sistema de Movilidad",
            "preregistro_id": alumno_id,
            "tipo_correo": "RECHAZO"
        };

        console.log('lo que mando en peticion', data2);

        axios.post(url2, data2, head)
            .then((res) => {
                window.Materialize.toast('Te hemos mandado un email para que activar tu cuenta', 1500, 'green')
            })

            .catch((err) => {
                console.log('error_1', err)
            });
    };
    handleForValidation =(e)=> {

        let {form} =this.state;
        let {value,name}=e.target;

        form[name]=value;
        console.log('form-------',form);
        this.setState({form});
    };

    checkDocs = () => {
        const url = "http://148.204.63.213:80/IPN-Central-Route/";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        let alumno_id = localStorage.getItem('bolStudent');
        //console.log(idUser);
        axios.get(`${url}/archivo/fileList/${alumno_id}`,headers)
            .then((res)=>{
                console.log('Verificar Docs',res.data);
                this.setState({infoDoc:res.data})
            })
            .catch(err=>console.log('error de Doc',err))
    };
    checkOpc = () => {
        const url = "http://148.204.63.213:80/IPN-Central-Route";

        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        let alumno_id = localStorage.getItem('bolStudent');
        console.log(alumno_id);
        axios.get(`${url}/usuario/instituto/getInstitutosByAlumnoID/${alumno_id}`,headers)
            .then((res)=>{
                console.log('Verificar Opciones',res.data.list_alumnos_solicitudes_institutos);
                this.setState({infoOpc:res.data.list_alumnos_solicitudes_institutos})
            })
            .catch(err=>console.log('error de Opc',err))
    };
    getCatalogs = ()=> {
        const token = localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/catalogos/`;
        //console.log('%c token ', 'color: green', token);
        const headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        //Peticición para listar   ESTADOS 4
        axios.get(`${url}/4`, headers)
            .then((res) => {
                console.log('%c catalogos 4 ESTADOS', 'color: pink', res.data.lista_catalogo_contenido);
                this.setState({catEnt: res.data.lista_catalogo_contenido})
            })
            .catch((err) => {
                console.log('err catalogo Estados ', err)
            });
        //Peticición para listar NACIONES 3
        axios.get(`${url}/3`, headers)
            .then((res) => {
                console.log('%c catalogos 3 naciones', 'color: yellow', res.data.lista_catalogo_contenido);
                this.setState({catNac: res.data.lista_catalogo_contenido})
            })
            .catch((err) => {
                console.log('err catalogo Naciones', err)
            });
    };
    componentDidMount() {
        this.sessionActive();
        this.checkDocs();
        this.checkOpc();
        this.getCatalogs();
        //console.log('Id Alumno:',localStorage.bolStudent);
        //this.checkDoc();
        //this.validarDocs();
    }

    render() {
        let {session, infoOpc, infoDoc}= this.state;
        //console.log("Paso",infoOpc);
        return(
            <>
                <NavbarCCA
                    logOut={this.LogOut}
                />

                {/*verifica que la sesión esta activa y si no te redirecciona al Home*/}
                {
                    session===true?
                        <>
                            <Redirect to="/login"/>
                        </>
                        :
                        <>
                            {/*<h1>Validar Registro->{this.state.boleta}</h1>*/ }
                            <Card
                                title="Validación de documentacuión"
                            >
                                <div className="pt">
                                        <Modal className="pt"
                                            header="Opciones del Postulante"
                                            trigger={
                                                <Button
                                                    name='universidades'
                                                >
                                                    Ver universidades
                                                </Button>
                                            }>
                                            {
                                                infoOpc&&
                                               <row>
                                                <br/>
                                                <Input
                                                    id="instituto_1_nombre"
                                                    type="text"
                                                    name="instituto_1_nombre"
                                                    value={infoOpc[0].instituto_1_nombre}
                                                    label={"Opción numero 1"}
                                                />
                                                <Input
                                                    id="instituto_2_nombre"
                                                    type="text"
                                                    name="instituto_2_nombre"
                                                    value={infoOpc[0].instituto_2_nombre}
                                                    label={"Opción numero 3"}
                                                />
                                                <Input
                                                    id="instituto_3_nombre"
                                                    type="text"
                                                    name="instituto_3_nombre"
                                                    value={infoOpc[0].instituto_3_nombre}
                                                    label={"Opción numero 3"}
                                                />
                                               </row>
                                            }
                                        </Modal>
                                </div>
                                <Table  striped={true}    className="white z-depth-2">
                                    <thead>
                                    <tr>
                                        <th WIDTH="40%" data-field="id">Documento</th>
                                        <th WIDTH="10%" data-field="file" > Ver</th>
                                        <th WIDTH="10%" data-field="name">Estatus</th>
                                        <th WIDTH="40%" data-field="desc">Nota</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td >1.- Carta de exposicion de motivos en Español firmada por el alumno</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CEE"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocCartaE}
                                            />
                                        </td>
                                        <td >
                                            <Input
                                                type="checkbox"
                                                className="ValCe"
                                                name="ValCe"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaCe"
                                                name="NotaCe"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td  >2.- Carta de exposicion de motivos en Inglés firmada por el alumno</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CEI"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocCartaI}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValCi"
                                                name="ValCi"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaCi"
                                                name="NotaCi"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td  >3.- Solicitud de participación en el programa de movilidad académica</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="SPPMA"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocSolicitudP}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValSo"
                                                name="ValSo"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaSo"
                                                name="NotaSo"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4.- Curriculum Vitae</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CV"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocCV}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValCV"
                                                name="ValCV"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaCV"
                                                name="NotaCV"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5.-Identificación oficial vigente (INE, Pasaporte, Cartilla de SM liberada</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="IOV"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocIdentOf}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValId"
                                                name="ValId"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaId"
                                                name="NotaId"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6.-Certificado médico</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CM"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocCertMed}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValCer"
                                                name="ValCer"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaCer"
                                                name="NotaCer"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7.-Carnet médico y SGMV </td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocCartMed}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValCar"
                                                name="ValCar"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaCar"
                                                name="NotaCar"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <p>Documentación de CCA</p>
                                    <tr>
                                        <td>8.- Oficio de postulación por parte del titular de la unidad académica</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocOficio}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValOff"
                                                name="ValOff"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaOff"
                                                name="NortaOff"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9.- Constancia de créditos y promedio emitida por la dirección correspondiente</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocConst}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValCons"
                                                name="ValCons"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaCons"
                                                name="NotaCons"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10.- Boleta global de estudios</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocBoleta}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValBol"
                                                name="ValBol"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaBol"
                                                name="NotaBol"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <p>Comprobantes de Idioma</p>
                                    <tr>
                                        <td>11.- Comprobante de Idioma inglés</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocIngles}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValIng"
                                                name="ValIng"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}

                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaIng"
                                                name="NotaIng"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12.- Comprobante de Segundo Idioma</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocSegundo}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValSeg"
                                                name="ValSeg"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="ValSeg"
                                                name="NotaSeg"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>13.- Comprobante de Tercer Idioma</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocTercer}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValTer"
                                                name="ValTer"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaTer"
                                                name="NotaTer"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>14.- Comprobante de Cuarto Idioma</td>
                                        <td >
                                            <Button
                                                hidden={true}
                                                floating
                                                small
                                                name="CMS"
                                                className="btn-ipn"
                                                waves="red"
                                                icon="picture_as_pdf"
                                                onClick={this.checkDocCuarto}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                className="ValCuar"
                                                name="ValCuar"
                                                label="Valido"
                                                class="filled-in"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className="NotaCuar"
                                                name="NotaCuar"
                                                type="text"
                                                label="Nota"
                                                onChange={this.handleForValidation}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <div className="pt" >
                                    <Button
                                        onClick={this.validarDocs}
                                    >
                                        Guardar y Notificar
                                    </Button>
                                </div>
                            </Card>
                        </>
                }
            </>
        )
    }
}
export default ValidateRegister
import  React,{useState, useEffect} from 'react'
import {Button, Card, Col, Input, Row} from "react-materialize";
import  axios from 'axios';
import Loader from 'react-loader-spinner'
import M from "materialize-css";


const Form = () => {
  const [form, setForm] = useState({ email: "", secretword: "" });

  const [loading,setLoading]= useState(false);

  const sumbmitForm=(e)=>{
    e.preventDefault();
    setLoading(true);

    const url = "http://148.204.63.213:80/IPN-Central-Route/preregistro/";
    const url2 = "http://148.204.63.213:80/IPN-Central-Route/correo/enviar ";

      let headers2 = {
          headers: {
              "Accept": "application/json",
              "HR-TOKEN-APPID": 2,
              "HR-APP-VERSION": "1.0.0",
              "HR-APP-TYPE": "WEB",
              "Content-Type": "application/json",
          }
      };
    if (form.email === form.email2 && form.secretword === form.secretword2) {
        const datosIniciales = {
            "boleta": form.boleta,
            "genero": form.genero,
            "email": form.email,
            "secretword": form.secretword,
            "tipo_movilidad":form.tipo_movilidad
        };
        console.log("form", datosIniciales);
        axios.post(url, datosIniciales, headers2)
            .then((res) => {
                console.log('res peticion', res);
                let data2 = {
                    "correo_destino": form.email,
                    "correo_asunto": "Validación pre- registró éxito",
                    "correo_contenido": "Tu pre-registro debe ser validado a medinate de la siguiente liga, utilizando los MISMOS datos que definiste en el pre-registro",
                    "preregistro_id": res.data.preregistro_id,
                    "tipo_correo": "ACEPTADO"
                };
                console.log('lo que mando en peticion', data2);
                axios.post(url2, data2, headers2)
                    .then((res) => {
                        window.Materialize.toast('Te hemos mandado un email para que activar tu cuenta', 1500, 'green')
                    })

                    .catch((err) => {
                        console.log('error_1', err)
                    });
                setLoading(false)
            })

      .catch((err)=>{
        console.log("entro al error 2",err);
        if(err.response.data.code){
          if (err.response.data.code===300) {
            window.Materialize.toast('Usuario ya existe', 1500, 'red')
          }else
          if (err.response.data.code===400) {

            let data2 = {
              "correo_destino":form.email,
              "correo_asunto": "Validación pre-registró NO exitoso",
              "correo_contenido": err.response.data.error_message,
              "preregistro_id": form.boleta,
               "tipo_correo": "RECHAZO"
            };
            console.log('lo que mando en peticion',data2);
            axios.post(url2,data2,headers2)
                .then((res)=>{
                    //console.log(err.response.data.error_message);
                  window.Materialize.toast(`${err.response.data.error_message}`, 1500, 'red');
                })
                .catch((err)=>{
                  console.log('error',err)
                });
          }
        }
        setLoading(false)
      })
    }
    else{
        window.Materialize.toast('E-mail o Contraseña incongruentes', 1500, 'red')
        setLoading(false)
    }
  };

  const onChange = (e) => {

    let name= e.target.name, value =e.target.value;
     setForm({ ...form, [name]: value });

  };

    useEffect(()=>{
          var elems = document.querySelectorAll('select');
          M.FormSelect.init(elems);
  });

  return (

    <div className="container pt" >
      <h5 className="txt-ipn"> PRE-REGISTRO </h5>
      {
        loading
        ?
        <>

          <Loader
            type="Rings"
            color="#4b0024"
            height="400"
            width="400"
          />
        </>
          :
        <>
          <Card className="z-depth-3">
            <form onSubmit={sumbmitForm} >
              Son necesarios los siguientes datos para tu pre-registro y activación de cuenta
                <br/><br/>
              <Row>
                <Input
                  required
                  validate
                  name="boleta"
                  label="*Boleta"
                  type="text"
                  onChange={onChange}
                />
                  {/*<Input
                      required
                      validate
                      name="genero"
                      label="*Genero"
                      type="text"
                      onChange={onChange}
                  />*/}
                  <div class="input-field col s3">
                      <select name="tipo_movilidad" onChange={onChange}>
                          <option value="" disabled selected>Tipo de Movilidad</option>
                          <option value="NACIONAL">NACIONAL</option>
                          <option value="INTERNACIONAL">INTERNACIONAL</option>
                      </select>
                      {/*<label>Género</label>*/}

                  </div>
                  <div className="input-field col s3">
                      <select name="genero" onChange={onChange}>
                          <option value="" disabled selected>Género</option>
                          <option value="MASCULINO">MASCULINO</option>
                          <option value="FEMENINO">FEMENINO</option>
                      </select>
                  </div>
              </Row>
                <Row>
                <Input
                  id="email"
                  l={4}
                  s={12}
                  name="email"
                  label="*Email"
                  required
                  validate
                  type="email"
                  onChange={onChange}
                />
                <Input
                  id="secretword"
                  l={3}
                  s={12}
                  name="secretword"
                  label="*Contraseña"
                  data-length="10"
                  required
                  validate
                  type="password"
                  onChange={onChange}
                />
              </Row>
              <Row>
                  <Input
                   id="email2"
                   l={4}
                   s={12}
                   name="email2"
                   label="*Confirmar Email"
                   required
                   validate
                   type="email"
                   onChange={onChange}
                  />
                  <Input
                      id="secretword2"
                   l={3}
                   s={12}
                   name="secretword2"
                   data-length="10"
                   label="*Confirmar Contraseña"
                   required
                   validate
                   type="password"
                   onChange={onChange}
                  />
              </Row>
              <Row >
                <br/>
               <label>* La contraseña debe tener mínimo 6 caracteres, no deberá contener caracteres especiales, sólo letras y números</label>
              </Row>
                <Row>
                <Col l={12} s={12}>
                  <Button  type="submit" >
                    Enviar
                  </Button>
                </Col>
              </Row>
                Si tienes duda sobre el correo recibido, comunícate con la Unidad Politécnica de Integración Social (UPIS) de tu Unidad Académica <a href={"https://www.ipn.mx/assets/files/secgeneral/docs/directorio/directorio.pdf"}>https://www.ipn.mx/assets/files/secgeneral/docs/directorio/directorio.pdf</a>
            </form>
          </Card>
        </>
      }
    </div>
  );
};
export default Form
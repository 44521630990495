import React, { Component } from 'react'
import axios from 'axios'
import NavbarUA from './NavabarUA';
import {Redirect} from "react-router-dom";
import {Card, Table, Input, Button, Modal} from 'react-materialize'
import Spinner from './Spinner';

class ValidateRegister extends Component {

  state={
    boleta:localStorage.getItem('bolStudent'), image: [],
    session:false,token : localStorage.getItem('token'),
      form:{},
      /*form:{
        ValCe: false,
        ValCi: false,
        ValSo: false,
        ValCV: false,
        ValId: false,
        ValCer: false,
        ValCar: false
      },*/
      saving: false,
      loading: true
  };

  sessionActive=()=>{
    let {token}= this.state;
    const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

    const newHead = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(newUrl, newHead)
      .then(res => {
        console.log("Token activo table")
      })
      .catch((err)=>{
        console.log("la sesion acabo");
        this.setState({session:true})
      })
  };

  LogOut = () => {

    let token=localStorage.getItem('token');
    console.log('entró al logout',token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(url, head)
      .then(res => {
        localStorage.removeItem('profile');
        localStorage.removeItem('userName');

        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('alumno_id');

        this.props.history.push("/login");
      })

      .catch(err=>console.log(err))

  };
    checkDocCartaE = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CartaEspanol/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Carta_Español.pdf";

                downloadLink.href = linkSource;
                downloadLink.target = '_blank';
                //downloadLink.download = fileName;
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocCartaI = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CartaIngles/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Carta_Ingles.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocSolicitudP = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/SolicitudMovilidad/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Solicitud_Movilidad.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocCV = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CurriculumVitae/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Curriculum_Vitae.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));
    };
    checkDocIdentOf = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/IdentificacionOficial/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Identificacion_Oficial.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };
    checkDocCertMed = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CertificadoMedico/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Certificado_Medico.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));
    };

    checkDocCartMed = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/CarnetMedico/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                console.log('res',res);
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "Carnet_Medico.pdf";

                downloadLink.href = linkSource;
                //downloadLink.download = fileName;
                downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };

    checkDocIngles = (e) => {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let token=localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/DominioIngles/${alumno_id}`;
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Carta:res.data.b64File});
                console.log('Archivo', res.data.b64File);
                const linkSource = `data:application/pdf;base64,${this.state.Carta}`;
                const downloadLink = document.createElement("a");
                const fileName = "DominioIngles.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                //downloadLink.target = '_blank';
                downloadLink.click();

            })
            .catch(err=>console.log("error en el Doc",err));

    };

/*el json que se usa para agregar el nivel ,el nombre del idioma , si es valiodo o no y las notas es el mismo un ejemplo de json es
{
"alumno_id": 449
"carta_exposicion_motivos_espanol_nota": "por que yolo",
"carta_exposicion_motivos_espanol_valida": true
}

los valores del json deben hacer match con los nombres que se desan modifcar de la base de datos
el endpoint es
/IPN-Central-Route/archivo/movilidadDocumentos/updateMetaD
ata*/
    handleForValidation =(e)=> {

        let {form} =this.state;
        let {value,name}=e.target;

        form[name]=value;
        console.log('form-------',form);
        this.setState({form});
    };
    handleForValidationCheck =(e)=> {

        let {form} =this.state;
        let {checked,name}=e.target;
        console.log(e.target);
        console.log(e.target.checked);
        //console.log(value);
        //form[name]=value ==='on';
        form[name]=checked;
        console.log('form-------',form);

        this.setState({form});
    };


    validarDocs=(e)=>{

        this.setState({saving:true});
        e.preventDefault();
        console.log('form',this.state.form);
        let alumno_id = localStorage.getItem('bolStudent');
        let valCertaEspañol = localStorage.getItem('valCe');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/movilidadDocumentos/updateMetaData`;
        let token = localStorage.getItem('token');
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        const datos={
            "alumno_id": alumno_id,
            "carta_exposicion_motivos_espanol_nota": this.state.form.NotaCe,
            "carta_exposicion_motivos_espanol_valida": this.state.form.ValCe,
            "carta_exposicion_motivos_ingles_nota": this.state.form.NotaCi,
            "carta_exposicion_motivos_ingles_valida": this.state.form.ValCi,
            "solicitud_programa_movilidad_academica_nota": this.state.form.NotaSol,
            "solicitud_programa_movilidad_academica_valida": this.state.form.ValSo,
            "curriculum_vitae_nota": this.state.form.NotaCV,
            "curriculum_vitae_valido": this.state.form.ValCV,
            "identificacion_oficial_nota": this.state.form.NotaId,
            "identificacion_oficial_valida":this.state.form.ValId,
            "certificado_medico_nota": this.state.form.NotaCer,
            "certificado_medico_valido": this.state.form.ValCer,
            "cartilla_carnet_seguro_medico_nota":this.state.form.NotaCar,
            "cartilla_carnet_seguro_medico_valida":this.state.form.ValCar

       };
        console.log(datos);
        axios.post(url, datos, head)
            .then((res)=>{
                console.log('Validar Docs',res);
                this.setState({infoDoc:res});
                this.noificareMail(e);
            })
            .catch(err=> {
                this.setState({saving:false});
                console.log('error al Validar',err);
            });
    };

    uploadOficio=(e)=> {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let {image} = this.state;
        let token = localStorage.getItem('token');

        console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${alumno_id}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //1
        const OficioPostulacion = new FormData();
        OficioPostulacion.append("archivo", this.state.image['OficioPostulacion']);
        OficioPostulacion.append("filename", this.state.image['OficioPostulacion'].name);

        axios.post(`${url}/OficioPostulacion`, OficioPostulacion, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Archivo guardado exitosamente",1500,'green');
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo oficio de postulción excede el tamaño maximo\"", 2000);
            })
    };
    uploadConstancia=(e)=> {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let {image} = this.state;
        let token = localStorage.getItem('token');

        console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${alumno_id}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //1
        const ConstanciaEstudios = new FormData();
        ConstanciaEstudios.append("archivo", this.state.image['ConstanciaEstudios']);
        ConstanciaEstudios.append("filename", this.state.image['ConstanciaEstudios'].name);

        axios.post(`${url}/ConstanciaEstudios`, ConstanciaEstudios, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Archivo guardado exitosamente",1500, 'green');
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo constancia de estudios excede el tamaño maximo\"", 2000);
            })
    };

    uploadBoleta=(e)=> {
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
        let {image} = this.state;
        let token = localStorage.getItem('token');

        console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${alumno_id}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //1
        const BoletaGlobal = new FormData();
        BoletaGlobal.append("archivo", this.state.image['BoletaGlobal']);
        BoletaGlobal.append("filename", this.state.image['BoletaGlobal'].name);

        axios.post(`${url}/BoletaGlobal`, BoletaGlobal, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Archivo guardado exitosamente",1500,'green');
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo boleta general excede el tamaño maximo\"", 2000);
            })
    };

    noificareMail=(e)=>{
        e.preventDefault();
        let alumno_id = localStorage.getItem('bolStudent');
       const url2 = "http://148.204.63.213:80/IPN-Central-Route/correo/enviar ";
        let email = localStorage.getItem('email');
        console.log(email);
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "Web",
                "Content-Type": "application/json",
            }
        };
                    let data2 = {
                        "correo_destino": email,
                        "correo_asunto": "Validación de Docuemntos",
                        "correo_contenido": "Tus documentos estan siendo validados, debes entrar a tu cuenta del sistema de Movilidad, para verificar el estatus de tus documentos",
                        "preregistro_id": alumno_id,
                        "tipo_correo": "RECHAZO"
                    };

                    console.log('lo que mando en peticion', data2);
                    axios.post(url2, data2, head)
                        .then((res) => {
                            this.setState({saving:false});
                            window.Materialize.toast('Hemos enviado un mail al alumno', 1500, 'green');
                            //this.validarDocs();
                            this.checkDocs();
                        })

                        .catch((err) => {
                            console.log('error_1', err);
                            this.setState({saving:false});
                            window.Materialize.toast('Tenemos proble para el envio de mails en este momento verica la dirección', 1500, 'green')
                        });
    };

    checkDocs = () => {
        const url = "http://148.204.63.213:80/IPN-Central-Route/";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        let alumno_id = localStorage.getItem('bolStudent');
        //console.log(idUser);
        axios.get(`${url}/archivo/fileList/${alumno_id}`,headers)
            .then((res)=>{
                console.log('Verificar Docs',res.data);
                this.setState({
                    infoDoc:res.data,
                    form: {
                        ...this.state.form,
                        ValCe: res.data && res.data.cartaExposicionMotivosEspanolValido ?
                            res.data.cartaExposicionMotivosEspanolValido:false,
                        ValCi: res.data && res.data.cartaExposicionMotivosInglesValido ?
                            res.data.cartaExposicionMotivosInglesValido:false,
                        ValSo: res.data && res.data.solicitudProgramaMovilidadAcademicaValido ?
                            res.data.solicitudProgramaMovilidadAcademicaValido:false,
                        ValCV: res.data && res.data.curriculumVitaeValido ?
                            res.data.curriculumVitaeValido:false,
                        ValId: res.data && res.data.identificacionOficialValido ?
                            res.data.identificacionOficialValido:false,
                        ValCer: res.data && res.data.certificadoMedicoValido ?
                            res.data.certificadoMedicoValido:false,
                        ValCar: res.data && res.data.cartillaCarnetSeguroMedicoValido ?
                            res.data.cartillaCarnetSeguroMedicoValido:false
                    },
                    loading: false
                })
            })
            .catch(err=>console.log('error de Doc',err))
    };

    handleChange = (e) => {
        let {image} = this.state;
        let file=e.target.name;
        image[file]= e.target.files[0];
        this.setState({ image })
    };

    componentDidMount() {
        this.sessionActive();
        this.checkDocs();
        //console.log('Id Alumno:',localStorage.bolStudent);
        //this.checkDoc();
        //this.validarDocs();
    }

  render() {
      let {session, infoDoc} =this.state;
      return(
        <>
          <NavbarUA 
            logOut={this.LogOut}
          />

          {/*verifica que la sesión esta activa y si no te redirecciona al Home*/}
          {
            session===true?
              <>
                <Redirect to="/login"/>
              </>
              :
              <>
                  {/*<h1>Validar Registro->{this.state.boleta}</h1>*/ }
                  <Card
                      title="Validación de documentación"
                  >
                  <Table  striped={true}    className="white z-depth-2">
                      <thead>
                      <tr>
                          <th WIDTH="40%" data-field="id">Documento</th>
                          <th WIDTH="10%" data-field="file" > Ver</th>
                          <th WIDTH="10%" data-field="name">Estatus</th>
                          <th WIDTH="40%" data-field="desc">Nota</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td >1.- Carta de exposición de motivos en español firmada por el alumno</td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="CEE"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocCartaE}
                              />
                          </td>
                          {
                              /*infoDoc&&
                              infoDoc.cartaExposicionMotivosEspanolValido?
                                  <>
                                  <td >
                                      <Input
                                          type="checkbox"
                                          className="ValCe"
                                          name="ValCe"
                                          label="Válido"
                                          class="filled-in"
                                          checked={true}
                                          value={true}
                                      />
                                  </td>
                                      <span>
                                      </span>
                                  </>
                                  :
                                  <>*/
                          }
                         <td>
                          {
                              !this.state.loading &&
                                  <Input
                                      type="checkbox"
                                      className="ValCe"
                                      name="ValCe"
                                      label="Válido"
                                      class="filled-in"
                                      onChange={this.handleForValidationCheck}
                                      checked={this.state.form.ValCe}
                                  />
                          }
                         </td>
                          <td>
                          {
                              !this.state.form.ValCe &&
                                  <Input
                                      className="NotaCe"
                                      name="NotaCe"
                                      type="text"
                                      label="Nota"
                                      onChange={this.handleForValidation}
                                  />
                          }
                          </td>
                      </tr>
                      <tr>
                          <td  >2.- Carta de exposición de motivos en inglés firmada por el alumno</td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="CEI"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocCartaI}
                              />
                          </td>
                          {/*
                              infoDoc &&
                              infoDoc.cartaExposicionMotivosInglesValido === true ?
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCi"
                                              name="ValCi"
                                              label="Válido"
                                              class="filled-in"
                                              checked={true}
                                          />
                                      </td>
                                      <span>
                                      </span>
                                  </>
                                  :
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCi"
                                              name="ValCi"
                                              label="Válido"
                                              class="filled-in"
                                              onChange={this.handleForValidationCheck}
                                              checked={this.state.form.ValCi}
                                          />
                                      </td>
                                  </>*/
                          }
                          <td>
                              {
                                  !this.state.loading &&
                                  <Input
                                      type="checkbox"
                                      className="ValCi"
                                      name="ValCi"
                                      label="Válido"
                                      class="filled-in"
                                      onChange={this.handleForValidationCheck}
                                      checked={this.state.form.ValCi}
                                  />
                              }
                          </td>
                          <td>
                              {
                                  !this.state.form.ValCi &&
                                  <Input
                                      className="NotaCi"
                                      name="NotaCi"
                                      type="text"
                                      label="Nota"
                                      onChange={this.handleForValidation}
                                  />
                              }
                          </td>
                      </tr>
                      <tr>
                          <td  >3.- Solicitud de participación en el programa de movilidad académica</td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="SPPMA"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocSolicitudP}
                              />
                          </td>
                          {/*
                              infoDoc &&
                              infoDoc.solicitudProgramaMovilidadAcademicaValido === true ?
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValSo"
                                              name="ValSo"
                                              label="Válido"
                                              class="filled-in"
                                              checked={true}
                                          />
                                      </td>
                                      <span>
                                      </span>
                                  </>
                                  :
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValSo"
                                              name="ValSo"
                                              label="Válido"
                                              class="filled-in"
                                              onChange={this.handleForValidationCheck}
                                              checked={this.state.form.ValSo}
                                          />
                                      </td>
                                  </>*/
                          }
                          <td>
                              {
                                  !this.state.loading &&
                                  <Input
                                      type="checkbox"
                                      className="ValSo"
                                      name="ValSo"
                                      label="Válido"
                                      class="filled-in"
                                      onChange={this.handleForValidationCheck}
                                      checked={this.state.form.ValSo}
                                  />
                              }
                          </td>
                          <td>
                              {
                                  !this.state.form.ValSo &&
                                  <Input
                                      className="NotaSol"
                                      name="NotaSol"
                                      type="text"
                                      label="Nota"
                                      onChange={this.handleForValidation}

                                  />
                              }
                          </td>
                      </tr>
                      <tr>
                          <td>4.- Curriculum Vitae</td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="CV"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocCV}

                              />
                          </td>
                          {/*
                              infoDoc &&
                              infoDoc.curriculumVitaeValido === true ?
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCV"
                                              name="ValCV"
                                              label="Válido"
                                              class="filled-in"
                                              checked={true}
                                          />
                                      </td>
                                      <span>
                                      </span>
                                  </>
                                  :
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCV"
                                              name="ValCV"
                                              label="Válido"
                                              class="filled-in"
                                              onChange={this.handleForValidationCheck}
                                              checked={this.state.form.ValCV}
                                          />
                                      </td>
                                  </>*/
                          }
                          <td>
                              {
                                  !this.state.loading &&
                                  <Input
                                      type="checkbox"
                                      className="ValCV"
                                      name="ValCV"
                                      label="Válido"
                                      class="filled-in"
                                      onChange={this.handleForValidationCheck}
                                      checked={this.state.form.ValCV}
                                  />
                              }
                          </td>
                          <td>
                              {
                                  !this.state.form.ValCV &&
                                  <Input
                                      className="NotaCV"
                                      name="NotaCV"
                                      type="text"
                                      label="Nota"
                                      onChange={this.handleForValidation}
                                  />
                              }
                          </td>
                      </tr>
                      <tr>
                          <td>5.-Identificación oficial vigente (INE, Pasaporte, Cartilla de S.M. liberada)</td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="IOV"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocIdentOf}
                              />
                          </td>
                          {/*
                              infoDoc &&
                              infoDoc.identificacionOficialValido === true ?
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValId"
                                              name="ValId"
                                              label="Válido"
                                              class="filled-in"
                                              checked={true}
                                          />
                                      </td>
                                      <span>
                                      </span>
                                  </>
                                  :
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValId"
                                              name="ValId"
                                              label="Válido"
                                              class="filled-in"
                                              onChange={this.handleForValidationCheck}
                                              checked={this.state.form.ValId}
                                          />
                                      </td>
                                  </>*/
                          }
                          <td>
                              {
                                  !this.state.loading &&
                                  <Input
                                      type="checkbox"
                                      className="ValId"
                                      name="ValId"
                                      label="Válido"
                                      class="filled-in"
                                      onChange={this.handleForValidationCheck}
                                      checked={this.state.form.ValId}
                                  />
                              }
                          </td>
                          <td>
                              {
                                  !this.state.form.ValId &&
                                  <Input
                                      className="NotaId"
                                      name="NotaId"
                                      type="text"
                                      label="Nota"
                                      onChange={this.handleForValidation}
                                  />
                              }
                          </td>
                      </tr>
                      <tr>
                          <td>6.-Certificado médico</td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="CM"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocCertMed}
                              />
                          </td>
                          {/*
                              infoDoc &&
                              infoDoc.certificadoMedicoValido === true ?
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCer"
                                              name="ValCer"
                                              label="Válido"
                                              class="filled-in"
                                              checked={true}
                                          />
                                      </td>
                                      <span>
                                      </span>
                                  </>
                                  :
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCer"
                                              name="ValCer"
                                              label="Válido"
                                              class="filled-in"
                                              onChange={this.handleForValidationCheck}
                                              checked={this.state.form.ValCer}
                                          />
                                      </td>
                                  </>*/
                          }
                          <td>
                          {
                              !this.state.loading &&
                              <Input
                                  type="checkbox"
                                  className="ValCer"
                                  name="ValCer"
                                  label="Válido"
                                  class="filled-in"
                                  onChange={this.handleForValidationCheck}
                                  checked={this.state.form.ValCer}
                              />
                          }
                          </td>
                          <td>
                              {
                                  !this.state.form.ValCer &&
                                  <Input
                                      className="NotaCer"
                                      name="NotaCer"
                                      type="text"
                                      label="Nota"
                                      onChange={this.handleForValidation}
                                  />
                              }
                          </td>
                      </tr>
                      <tr>
                          <td>7.-Carnet médico y SGMV </td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="CMS"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocCartMed}
                              />
                          </td>
                          {/*
                              infoDoc &&
                              infoDoc.cartillaCarnetSeguroMedicoValido === true ?
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCar"
                                              name="ValCar"
                                              label="Válido"
                                              class="filled-in"
                                              checked={true}
                                          />
                                      </td>
                                      <span>
                                      </span>
                                  </>
                                  :
                                  <>
                                      <td>
                                          <Input
                                              type="checkbox"
                                              className="ValCar"
                                              name="ValCar"
                                              label="Válido"
                                              class="filled-in"
                                              onChange={this.handleForValidationCheck}
                                              checked={this.state.form.ValCar}
                                          />
                                      </td>
                                  </>*/
                          }
                          <td>
                              {
                                  !this.state.loading &&
                                  <Input
                                      type="checkbox"
                                      className="ValCar"
                                      name="ValCar"
                                      label="Válido"
                                      class="filled-in"
                                      onChange={this.handleForValidationCheck}
                                      checked={this.state.form.ValCar}
                                  />
                              }
                          </td>
                          <td>
                              {
                                  !this.state.form.ValCar &&
                                  <Input
                                      className="NotaCar"
                                      name="NotaCar"
                                      type="text"
                                      label="Nota"
                                      onChange={this.handleForValidation}
                                  />
                              }
                          </td>
                      </tr>
                      <tr>
                          <td>8.-Comprobante de dominio inglés </td>
                          <td >
                              <Button
                                  hidden={true}
                                  floating
                                  small
                                  name="CMS"
                                  className="btn-ipn"
                                  waves="red"
                                  icon="picture_as_pdf"
                                  onClick={this.checkDocIngles}
                              />
                          </td>
                      </tr>
                      </tbody>
                  </Table>
                      <div className="pt">
                          <Modal
                              header="Confirmación"
                              trigger={
                                  <Button name='tabla_idiomas'>Guardar y notificar </Button>
                              }>
                          {
                              this.state.saving ?
                                  (<Spinner/>):(
                              <Button
                                  data-target="modal1"
                                  onClick={this.validarDocs}
                              >
                                  Aceptar
                              </Button>
                                  )
                          }
                          </Modal>
                      </div>
                  </Card>
                  <Card
                      title="Documentación de la Unidad Académica"
                  >
                      <Table  striped={true}    className="white z-depth-2">
                          <thead>
                          <tr>
                              <th data-field="id">Documento  </th>
                              <th data-field="name">Archivo </th>
                              <th data-field="Button">Guardar </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                              <td>1.- Oficio de postulación por parte del Director de la Unidad Académica</td>
                              {
                                  infoDoc &&
                                  infoDoc.OficioPostulacion === true
                                      ?
                                      <>
                                          {
                                              infoDoc.oficioPostulacionValido === true
                                                  ?
                                                  <>
                                                      <td style={{textAlign: 'center'}}>
                                                      </td>
                                                      <td style={{textAlign: 'center'}}>
                                                      </td>
                                                      <td style={{textAlign: 'center'}}>
                                                          <img src="../../images/verde.png" width="25" height="25"/>
                                                      </td>
                                                  </>
                                                  : ""
                                          }
                                          {
                                              infoDoc.oficioPostulacionValido === false
                                                  ?
                                                  <>
                                                      <td style={{textAlign: 'center'}}>
                                                          <Input
                                                              required
                                                              label="actualizar"
                                                              type='file'
                                                              name='OficioPostulacion'
                                                              onChange={this.handleChange}
                                                              accept='image/x-png,image/jpeg,application/pdf'
                                                          />
                                                      </td>
                                                      < td style={{textAlign: 'center'}}>
                                                          <Button
                                                              onClick={this.uploadOficio}
                                                          >
                                                              subir
                                                          </Button>
                                                      </td>
                                                      <td style={{textAlign: 'center'}}>
                                                          <img src="../../images/rojo.png" width="25" height="25"/>
                                                          <div className="pt">
                                                              <Modal
                                                                  trigger={
                                                                      <img src="../../images/help.png" width="25" height="25"/>
                                                                  }>
                                                                  <Input
                                                                      required
                                                                      label="Motivos"
                                                                      type='text'
                                                                      name='NotaCe'
                                                                      value={infoDoc.oficioPostulacionNota}
                                                                  />
                                                              </Modal>
                                                          </div>
                                                      </td>
                                                  </>
                                                  : ""
                                          }
                                          {
                                              infoDoc.oficioPostulacionValido === null
                                                  ?
                                                  <>
                                                      <td style={{textAlign: 'center'}}>
                                                          <Input
                                                              required
                                                              label="Examinar"
                                                              type='file'
                                                              name='OficioPostulacion'
                                                              onChange={this.handleChange}
                                                              accept='image/x-png,image/jpeg,application/pdf'
                                                          />
                                                      </td>
                                                      < td style={{textAlign: 'center'}}>
                                                          <Button
                                                              onClick={this.uploadOficio}
                                                          >
                                                              subir
                                                          </Button>
                                                      </td>
                                                      <td style={{textAlign: 'center'}}>
                                                          <img src="../../images/amarillo.png" width="25" height="25"/>
                                                      </td>
                                                  </>
                                                  : ""
                                          }
                                      </>
                                      :
                                      <>
                                          <td style={{textAlign: 'center'}}>
                                              <Input
                                                  required
                                                  label="Examinar"
                                                  type='file'
                                                  name='OficioPostulacion'
                                                  onChange={this.handleChange}
                                                  accept='image/x-png,image/jpeg,application/pdf'
                                              />
                                          </td>
                                          < td style={{textAlign: 'center'}}>
                                              <Button
                                                  onClick={this.uploadOficio}
                                              >
                                                  subir
                                              </Button>
                                          </td>
                                          <td style={{textAlign: 'center'}}>
                                              <p>S/D</p>
                                          </td>
                                      </>
                              }

                          </tr>
                          {/*<tr>
                          <td>1.- Oficio de postulación por parte del Director de la Unidad Académica</td>
                          <td>
                              <Input
                                  required
                                  label="agregar"
                                  type='file'
                                  name='OficioPostulacion'
                                  onChange={this.handleChange}
                                  accept='image/x-png,image/jpeg,application/pdf'
                              />
                          </td>
                          <td>
                              <Button
                                  onClick={this.uploadOficio}
                              >
                                  subir
                              </Button>
                          </td>
                          </tr>
                          <tr>
                          <td>2.- Constancia de estudios</td>
                          <td>
                              <Input
                                  required
                                  label="agregar"
                                  type='file'
                                  name='ConstanciaEstudios'
                                  onChange={this.handleChange}
                                  accept='image/x-png,image/jpeg,application/pdf'
                              />
                          </td>
                          <td>
                          <Button
                              onClick={this.uploadConstancia}
                          >
                              subir
                          </Button>
                          </td>
                      </tr>*/}
                      <tr>
                          <td>2.- Constancia de estudios</td>
                          {
                              infoDoc &&
                              infoDoc.ConstanciaEstudios === true
                                  ?
                                  <>
                                      {
                                          infoDoc.constanciaEstudiosValido === true
                                              ?
                                              <>
                                                  <td style={{textAlign: 'center'}}>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                      <img src="../../images/verde.png" width="25" height="25"/>
                                                  </td>
                                              </>
                                              : ""
                                      }
                                      {
                                          infoDoc.constanciaEstudiosValido === false
                                              ?
                                              <>
                                                  <td style={{textAlign: 'center'}}>
                                                      <Input
                                                          required
                                                          label="actualizar"
                                                          type='file'
                                                          name='ConstanciaEstudios'
                                                          onChange={this.handleChange}
                                                          accept='image/x-png,image/jpeg,application/pdf'
                                                      />
                                                  </td>
                                                  < td style={{textAlign: 'center'}}>
                                                      <Button
                                                          onClick={this.uploadConstancia}
                                                      >
                                                          subir
                                                      </Button>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                      <img src="../../images/rojo.png" width="25" height="25"/>
                                                      <div className="pt">
                                                          <Modal
                                                              trigger={
                                                                  <img src="../../images/help.png" width="25" height="25"/>
                                                              }>
                                                              <Input
                                                                  required
                                                                  label="Motivos"
                                                                  type='text'
                                                                  name='NotaCe'
                                                                  value={infoDoc.constanciaEstudiosNota}
                                                              />
                                                          </Modal>
                                                      </div>
                                                  </td>
                                              </>
                                              : ""
                                      }
                                      {
                                          infoDoc.constanciaEstudiosValido === null
                                              ?
                                              <>
                                                  <td style={{textAlign: 'center'}}>
                                                      <Input
                                                          required
                                                          label="Examinar"
                                                          type='file'
                                                          name='ConstanciaEstudios'
                                                          onChange={this.handleChange}
                                                          accept='image/x-png,image/jpeg,application/pdf'
                                                      />
                                                  </td>
                                                  < td style={{textAlign: 'center'}}>
                                                      <Button
                                                          onClick={this.uploadConstancia}
                                                      >
                                                          subir
                                                      </Button>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                      <img src="../../images/amarillo.png" width="25" height="25"/>
                                                  </td>
                                              </>
                                              : ""
                                      }
                                  </>
                                  :
                                  <>
                                      <td style={{textAlign: 'center'}}>
                                          <Input
                                              required
                                              label="Examinar"
                                              type='file'
                                              name='ConstanciaEstudios'
                                              onChange={this.handleChange}
                                              accept='image/x-png,image/jpeg,application/pdf'
                                          />
                                      </td>
                                      < td style={{textAlign: 'center'}}>
                                          <Button
                                              onClick={this.uploadConstancia}
                                          >
                                              subir
                                          </Button>
                                      </td>
                                      <td style={{textAlign: 'center'}}>
                                          <p>S/D</p>
                                      </td>
                                  </>
                          }
                      </tr>
                      <tr>
                          <td>3.- Boleta global de estudios</td>
                          {
                              infoDoc &&
                              infoDoc.BoletaGlobal === true
                                  ?
                                  <>
                                      {
                                          infoDoc.boletaGlobalValido === true
                                              ?
                                              <>
                                                  <td style={{textAlign: 'center'}}>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                      <img src="../../images/verde.png" width="25" height="25"/>
                                                  </td>
                                              </>
                                              : ""
                                      }
                                      {
                                          infoDoc.boletaGlobalValido === false
                                              ?
                                              <>
                                                  <td style={{textAlign: 'center'}}>
                                                      <Input
                                                          required
                                                          label="actualizar"
                                                          type='file'
                                                          name='BoletaGlobal'
                                                          onChange={this.handleChange}
                                                          accept='image/x-png,image/jpeg,application/pdf'
                                                      />
                                                  </td>
                                                  < td style={{textAlign: 'center'}}>
                                                      <Button
                                                          onClick={this.uploadBoleta}
                                                      >
                                                          subir
                                                      </Button>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                      <img src="../../images/rojo.png" width="25" height="25"/>
                                                      <div className="pt">
                                                          <Modal
                                                              trigger={
                                                                  <img src="../../images/help.png" width="25" height="25"/>
                                                              }>
                                                              <Input
                                                                  required
                                                                  label="Motivos"
                                                                  type='text'
                                                                  name='NotaCe'
                                                                  value={infoDoc.boletaGlobalNota}
                                                              />
                                                          </Modal>
                                                      </div>
                                                  </td>
                                              </>
                                              : ""
                                      }
                                      {
                                          infoDoc.boletaGlobalValido === null
                                              ?
                                              <>
                                                  <td style={{textAlign: 'center'}}>
                                                      <Input
                                                          required
                                                          label="Examinar"
                                                          type='file'
                                                          name='BoletaGlobal'
                                                          onChange={this.handleChange}
                                                          accept='image/x-png,image/jpeg,application/pdf'
                                                      />
                                                  </td>
                                                  < td style={{textAlign: 'center'}}>
                                                      <Button
                                                          onClick={this.uploadBoleta}
                                                      >
                                                          subir
                                                      </Button>
                                                  </td>
                                                  <td style={{textAlign: 'center'}}>
                                                      <img src="../../images/amarillo.png" width="25" height="25"/>
                                                  </td>
                                              </>
                                              : ""
                                      }
                                  </>
                                  :
                                  <>
                                      <td style={{textAlign: 'center'}}>
                                          <Input
                                              required
                                              label="Examinar"
                                              type='file'
                                              name='BoletaGlobal'
                                              onChange={this.handleChange}
                                              accept='image/x-png,image/jpeg,application/pdf'
                                          />
                                      </td>
                                      < td style={{textAlign: 'center'}}>
                                          <Button
                                              onClick={this.uploadBoleta}
                                          >
                                              subir
                                          </Button>
                                      </td>
                                      <td style={{textAlign: 'center'}}>
                                          <p>S/D</p>
                                      </td>
                                  </>
                          }
                              {/*<td>3.- Boleta global de estudios</td>
                          <td>
                              <Input
                                  required
                                  label="agregar"
                                  type='file'
                                  name='BoletaGlobal'
                                  onChange={this.handleChange}
                                  accept='image/x-png,image/jpeg,application/pdf'
                              />
                          </td>
                          <td>
                          <Button
                              onClick={this.uploadBoleta}
                          >
                              subir
                          </Button>
                          </td>*/}
                      </tr>
                      </tbody>
                  </Table>
                  </Card>
              </>
          }
        </>
      )
  }
}
export default ValidateRegister 
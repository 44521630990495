import React, { Component } from 'react';
import Pdf from "react-to-pdf";
import axios from "axios";
import '../styles/pdf.css'
import {Input, Row, Col, Card} from "react-materialize";

const ref = React.createRef();

class Pdf1 extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
        session:false, token : localStorage.getItem('token')
    };
    checkInfoDae = () => {
        const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/Alumnos";
        const url2 = "http://148.204.63.213:80/IPN-Central-Route/preregistro/serviceDaeFull";
        let headers2 = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "Content-Type": "application/json",
            }
        };
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(url,headers)
            .then((res)=>{
                //console.log('Verificar Datos',res.data);
                this.setState({infoPersonales:res.data});
                const boleta = {
                    "boleta": res.data.tabla_alumnos.boleta
                };
                axios.post(url2,boleta ,headers2)
                    .then((res)=> {
                        //console.log('INFO DAE', res);
                        this.setState({infoDatos:res.data});
                    })
                    .catch((err)=>{
                        console.log('error DAE',err)
                    })
            })
            .catch(err=>console.log('error de DAE',err));
    };

    getOpc = ()=> {
        let idUser=localStorage.getItem('userId');
        let idAlumno=localStorage.getItem('idAlumno');
        const token = localStorage.getItem('token');
        let idSolicitud = localStorage.getItem('idSolicitud');
        //console.log('Alumno',idAlumno);
        //console.log('usuario',idUser);
        const url = `http://148.204.63.213/IPN-Central-Route/usuario/instituto/getInstitutosBySolicitudId`;
        //console.log('%c token ', 'color: green', token);
        const headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        //Peticición para listar   OPCIONES
        axios.get(`${url}/${idSolicitud}`, headers)
            .then((res) => {
                console.log('Opciones de solicitud', 'color: blue', res.data.list_alumnos_solicitudes_institutos[0]);
                this.setState({solicitud: res&& res.data&& res.data.list_alumnos_solicitudes_institutos[0]}, ()=>this.decidedView());
                //this.setState({catEnt: res.data.lista_catalogo_contenido}) solicitudes_institutos
            })
            .catch((err) => {
                console.log('err opciones', err)
            });
    };
    setOptionNames(){
        if (typeof this.state.infoInt !== "undefined" && typeof this.state.infoNac !== "undefined" && typeof this.state.infoSolicitudes !== "undefined"){
            const universities = this.state.infoInt.institutos_internacionales.concat(this.state.infoNac.institutos_nacionales);
            const opc1 =  universities.find(u=>u.id === this.state.infoSolicitudes.instituto_1);
            const opc2 =  universities.find(u=>u.id === this.state.infoSolicitudes.instituto_2);
            const opc3 =  universities.find(u=>u.id === this.state.infoSolicitudes.instituto_3);
            this.setState({instituto_1:opc1&& opc1.nombre_instituto, instituto_2:opc2&& opc2.nombre_instituto, instituto_3:opc3&& opc3.nombre_instituto});
        }
    };
    decidedView(){
        if (this.state.solicitud&& this.state.solicitud.solicitud_id === this.state.staConvo)
        {
            //this.setState({solicitudes_institutos:true});
            this.setState({infoSolicitudes:this.state.solicitud}, ()=>this.setOptionNames());
            console.log('Solicitudes',this.state.infoSolicitudes);
        }
    };
    checkInternacionales=()=>{

        const url = "http://148.204.63.213:80/IPN-Central-Route";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}/usuario/institutos/Internacionales`,headers)
            .then((res)=> {
                //console.log('Verificar Institutos Internacionales', res.data);
                this.setState({infoInt:res.data}, ()=>this.setOptionNames())
            })
            .catch(err=>console.log('error de Institutos Internacionales',err));
    };
    checkNacionales=()=>{
        const url = "http://148.204.63.213:80/IPN-Central-Route";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}/usuario/institutos/Nacionales`,headers)
            .then((res)=> {
                //console.log('Verificar Institutos Nacionales', res.data);
                this.setState({infoNac:res.data}, ()=>this.setOptionNames())

            })
            .catch(err=>console.log('error de Institutos Nacionales',err));
    };
    checkConvo = () => {
        const url="http://148.204.63.213:80/IPN-Central-Route/usuario";
        let token=localStorage.getItem('token');
        let headers= {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}/datos/Alumnos`,headers)
            .then((res)=>{
                //console.log('convocatoria actual ',res.data.tabla_solicitudes_seguimientos);
                this.setState({staConvo:res.data.tabla_solicitudes_seguimientos.solicitud_id},()=>this.decidedView());
                //console.log(this.state.staConvo);
            })
            .catch(err=>console.log('erro de convo',err))
    };
    sessionActive=()=>{
        let {token}= this.state;
        const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

        const newHead = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };

        axios.get(newUrl, newHead)
            .then(res => {
                //console.log(res);
                //console.log("Token activo")
            })
            .catch((err)=>{
                console.log("la sesion acabo");
                this.setState({session:true})
            })
    };
    checkPhotograph=()=>{
        let idUser=localStorage.getItem('userId');
        let token=localStorage.getItem('token');
        let idAlumno=localStorage.getItem('idAlumno');

        console.log(' idUser',idUser);
        console.log('token',token);

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/FotoJpg500x500/${idAlumno}`;

        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                //"Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                this.setState({Fotografia:res.data.b64File});
                //console.log('Photograph',this.state.Fotografia);
            })
            .catch(err=>console.log('Photograph error',err));

    };
    componentDidMount() {
        this.sessionActive();
        this.checkConvo();
        this.checkInfoDae();
        this.checkInternacionales();
        this.checkNacionales();
        this.getOpc();
        this.checkPhotograph();
    }


    render() {
        const { infoDatos, Fotografia, infoNac, infoInt, infoSolicitudes}= this.state;

        console.log('Paso', infoSolicitudes);
        //console.log('Paso_2',infoNac, infoInt);
        return (
            <div className="App " >
                <Pdf targetRef={ref} filename="Ficha de registro.pdf">
                    {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
                </Pdf>
                <div className="container" ref={ref} style={{width: 794, height: 750, padingTop: 0,  background:"green"}} >

                    <div style={{width:790, height:"100%",  margin:"0px auto", background:"white"}} >

                    <h5 className="txt-ipn"> DATOS GENERALES </h5>
                        <br/>
                        {
                        infoDatos&&
                            <form>
                                <Row>
                                    <Col>
                                <img src={"data:image/jpg;base64," + Fotografia } width="150" alt=""/>
                                    </Col>
                                    <Input
                                        id="nombres"
                                        l={3}
                                        s={10}
                                        required
                                        validate
                                        type="text"
                                        name="nombres"
                                        value={infoDatos.NOMBRE}
                                        label="Nombre (s)"
                                    />
                                    <Input
                                        l={3}
                                        s={10}
                                        name="ap_paterno"
                                        value={infoDatos.PATERNO}
                                        label="Apellido Paterno"
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        l={3}
                                        s={10}
                                        name="ap_materno"
                                        value={infoDatos.MATERNO}
                                        label="Apellido Materno"
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        l={2}
                                        s={10}
                                        name="boleta"
                                        value={infoDatos.BOLETA}
                                        label="Boleta"
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        id="curp"
                                        l={3}
                                        s={12}
                                        name="curp"
                                        value={infoDatos.CURP}
                                        onChange={this.handleChange}
                                        label="CURP"
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        l={1}
                                        s={12}
                                        name="promedio"
                                        value={infoDatos.PROMEDIO}
                                        label="Promedio"
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        l={2}
                                        s={12}
                                        name="avance"
                                        value={infoDatos.AVANCE}
                                        label="Avance en Cred"
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        l={1}
                                        s={12}
                                        name="semestre"
                                        value={infoDatos.MAXIMO_SEMESTRE_CURSADO}
                                        label="Semestre"
                                        required
                                        validate
                                        type="text"
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        l={6}
                                        s={12}
                                        name="carrera"
                                        value={infoDatos.CARRERA}
                                        label="Carrera"
                                        required
                                        validate
                                        type="text"
                                    />
                                    {/*<Input
                                        l={6}
                                        s={12}
                                        name="uAcademica"
                                        value={"ESIQUIE"}
                                        label="Unidad Académica"
                                        required
                                        validate
                                        type="text"
                                    />*/}
                                </Row>
                            </form>
                    }
                    <h5 className="txt-ipn"> Destinos Seleccionados </h5>
                        {
                            infoSolicitudes && infoNac&& infoInt&&
                            <form>
                            <Row>
                                    <Col s={2}>
                                        <Input
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcSeleccionadas"
                                            value={infoSolicitudes.solicitud_id}
                                            label={"No de Solicitud"}

                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <Input
                                            s={12}
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcUno"
                                            value={this.state.instituto_1}
                                            placeholder={"Opción numero 1"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <Input
                                            s={12}
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcDos"
                                            value={this.state.instituto_2}
                                            placeholder={"Opción numero 2"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12} >
                                        <Input
                                            s={12}
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcTres"
                                            value={this.state.instituto_3}
                                            placeholder={"Opción numero 3"}
                                        />
                                    </Col>
                                </Row>
                            </form>
                        }
                    </div>
                </div>
            </div>

        );
    }
}
export default Pdf1;

/*
                                    <img src={"data:image/jpg;base64," + Fotografia } width="150" alt=""/>

1.- Sin logo de CCA

2.  En el titulo favor de especificar el tipo de movilidad (internacional, nacional, superior, posgrado)

3.- Especificación en los campos, es decir: nombre, apellido materno, paterno, boleta, CURP, etc

4.- En esa misma sección falta Unidad Académica

5.- En esa misma sección falta Teléfono del alumno y/o teléfono celular y correo electrónico

6.- Favor de no visualizar la dirección ni en el alumno ni en el contacto de emergencia

7.- Estilo del documento (tipo de fuente, tamaño de letra, títulos en negritas, etc)

8.- Proponemos que alguna sección de la parte superior derecha se visualice el número consecutivo o número de folio
 */


import React, {Component} from 'react';
import NavbarGeneral from "./NavbarGeneral";
import {Col, Row} from 'react-materialize'
import '../styles/login.css'


class SinAcceso extends Component{


    render() {
        return (
            <>
                <NavbarGeneral/>
                            <Row className="pt">
                                <Col l={2}  className=" "  >
                                </Col>
                                <Col l={4} m={12} className=" "  >
                                    <img src="../../images/CCA_big.png" alt=""/>
                                </Col>
                                <Col l={4} m={12} className=" "  >
                                    El periodo de registro ha concluido, espera tus resultados
                                </Col>
                                <Col l={2}   className=" "  >
                                </Col>
                            </Row>
            </>
        );
    }
}

export default SinAcceso;
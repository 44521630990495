import React, {useEffect} from 'react'
import NavbarGeneral from "./NavbarGeneral";
import {Button, Col, Row} from 'react-materialize'
import {Link} from "react-router-dom";
import Pdf from './Manual_Alumno_IPN.pdf';
import FormLogin from "./FormLogin";

const Home = () =>{
  useEffect(()=>{
    localStorage.removeItem('profile');
    localStorage.removeItem('userName');

    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('alumno_id');
    localStorage.removeItem('bolStudent');
  });

  return(
    <>
      <NavbarGeneral/>

      <div className="container">
        <div  className="father">
         <div className="line"></div>
        </div>
        <h4 className="txt-ipn">Coordinación de Cooperación Académica</h4>
       {/*
        <p className="txt-description"> Es responsable de fomentar, en coordinación con las diversas instancias del IPN, el desarrollo de actividades académicas de cooperación e internacionalización entre nuestra casa de estudios y otras organizaciones educativas, científicas y culturales del país y del extranjero.</p>

        <div className="ft-but">
          <div className="btn-ipn">
            <a href="/" className="lin" > Convocatoria Movilidad  Nacional N.S. (Ene-Jun 2020)</a>
          </div>
          <div className="btn-ipn">
            <a  href="/"  > Convocatoria Movilidad Internacional N.S. (Ene-Jun 2020)</a>
          </div>
          <div className="btn-ipn">
            <a   href="/" > Convocatoria Movilidad Internacional Posgrado (2020)</a>
          </div>
        </div>
        <div className="line"></div>
        <div className="ft-but mt">
          <div>
            <h5 className="txt-ipn">Comunidad Politécnica </h5>
            <Link to="/login">
              <Button className="btn-ipn">
                iniciar sesión
              </Button>
            </Link>
          </div>

          <div>
            <h5 className="txt-ipn"> Manual de usuario (Alumno)</h5>
            <Link to = {Pdf} target = "_blank">
              <Button className="btn-ipn">
                Ver PDF
              </Button>
            </Link>
          </div>
        </div>*/}
        <Row className="pt">
          <Col l={1}  className=" "  >
          </Col>
          <Col className=" "  >
            {/*<img src="../../images/confinamiento1.jpg" alt=""/>*/}
            <img src="../../images/comunicado.png" alt=""/>
          </Col>

        </Row>

      </div>

    </>
  )
};

export default Home;
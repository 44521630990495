import React, {Component} from 'react'
import TableViewer from "react-js-table-with-csv-dl";
import NavbarCCA from "./NavbarCCA";
import {Redirect} from "react-router-dom";
import axios from "axios";
import {Button, Col, Icon, Row} from "react-materialize";

import '../styles/navbar.css'

class PreregistrosCCA extends Component{

    state={
        session:false,token : localStorage.getItem('token'),word:"",typeSearch:"nombres",headers:["Boleta","Nombre", "Paterno", "Materno", "Promedio", "CURP", "email","Fecha_Reg"]
        ,boleta:false,nombres:false,bolStu:"",table:[],data:[]
    };

    sessionActive=()=>{
        let {token}= this.state;
        const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

        const newHead = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };

        axios.get(newUrl, newHead)
            .then(res => {
                console.log("Token activo table")
            })
            .catch((err)=>{
                console.log("la sesion acabo")
                this.setState({session:true})
            })
    };

    getStyles=()=>{
        let {headers}= this.state
        let tablez=document.getElementsByClassName('divTableCell');
        for(let i =0;i<=headers.length;i++){
            tablez[i].style.backgroundColor="#4b0024"
        }
    };

    componentDidMount() {
        this.sessionActive();
        this.getStyles();
        this.getStudent();
    }

    orderBy=(e)=>{
        console.log("e",e.target.value);
        let tempArray = this.state.data;
        let criterio= e.target.value;

        if(criterio==="nombres"){
            tempArray.sort((a, b) => a.Nombre !== b.Nombre ? a.Nombre < b.Nombre? -1 : 1 : 0);
        }

        if(criterio==="promedio") {
            tempArray.sort((a, b) => a.Promedio !== b.Promedio ? a.Promedio > b.Promedio ? -1 : 1 : 0);
        }
        this.setState({table:tempArray})
    };


    intervalo=()=>{
        let tempArray = this.state.data;
        tempArray.filter(tempArray => {return (tempArray.Fecha_Reg > "2020-02-03" && tempArray.Fecha_Reg < "2020-02-05");});
        //tempArray().isBetween (tempArray.Fecha_Reg ("2020-02-03"), tempArray.Fecha_Reg ("2020-02-05"));
        this.setState({table:tempArray})
    };

    LogOut = () => {

        let token=localStorage.getItem('token');
        console.log('entró al logout',token);

        const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                console.log('res de logout', res);


                localStorage.removeItem('profile');
                localStorage.removeItem('userName');

                localStorage.removeItem('userId');
                localStorage.removeItem('token');
                localStorage.removeItem('alumno_id');

                this.props.history.push("/login");
            })
            .catch(err=>console.log(err))
    };

    getStudent=()=>{
        let {table }= this.state;
        let token=localStorage.getItem('token');
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
           axios.get("http://148.204.63.213:80/IPN-Central-Route/preregistro/lista",head)
            .then((res)=>{
                //console.log("Completo",res);
                table=res.data.lista_prereg_no_activados;
                this.setState({table});
                this.fixData()
            })
            .catch(err=>console.log(err))

    };

    fixData=()=>{
        let {table}= this.state;
        console.log(table);
        let arrFix=[];

        table.map((el,i)=>{
            return(
                arrFix[i]={},
                    arrFix[i].Boleta=el.boleta,
                    arrFix[i].Nombre=el.nombres,
                    arrFix[i].Paterno=el.ap_paterno,
                    arrFix[i].Materno=el.ap_materno,
                    arrFix[i].Promedio=el.promedio,
                    arrFix[i].CURP=el.curp,
                    arrFix[i].email=el.email,
                    arrFix[i].Fecha_Reg=el.reg_creado
                    //arrFix[i].Activo=el.activacion_usuario
            )
        });
        this.setState({data:arrFix,table:arrFix})
    };

    render() {
        let {data,headers,session}= this.state;
        return(
            <>
                <NavbarCCA
                    logOut={this.LogOut}
                />
                {
                    session===true?
                        <>
                            <Redirect to="/login"/>
                        </>
                        :
                        <>
                                <div id="nacional" className="col s12">
                                    <p>Total de registros</p>
                                    <Row className="pt">
                                        <Col hidden={false}  m={3}  >
                                            <select   className="browser-default" name="perfil_id"  onChange={this.orderBy}  >
                                                <option defaultValue  >Ordenar por </option>
                                                <option value="nombres">Nombre</option>
                                                <option value="promedio">promedio</option>
                                            </select>
                                        </Col>
                                        <Col>
                                            <Button onClick={this.intervalo} />
                                        </Col>
                                    </Row>

                                    <TableViewer
                                        content={data}
                                        headers={headers}
                                        minHeight={0}
                                        maxHeight={500}
                                        activateDownloadButton={true}
                                        filename={"Pre-registros.csv"}
                                        pagination={20}
                                        renderLineNumber
                                        searchEnabled={true}
                                        placeholderSearchText={"Cadena a buscar"}
                                        caseInsensitive={true}
                                    />
                                </div>
                        </>
                }
            </>
        )
    }

}
export default PreregistrosCCA
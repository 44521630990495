import React, {Component} from 'react'
import {Card, Table, Input, Button, Col, Modal} from 'react-materialize'
import NavbarStud from "./NavbarStud";
import axios from "axios";
import Spinner from "../UA/Spinner";


class AddDocStud extends Component {
    state = {
        image: [], staConvo: null, infoConvo: null
    };
    LogOut = () => {

        let token = localStorage.getItem('token');
        console.log('entró al logout', token);

        const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };

        axios.get(url, head)
            .then(res => {
                console.log('res de logout', res);


                localStorage.removeItem('profile');
                localStorage.removeItem('userName');

                localStorage.removeItem('userId');
                localStorage.removeItem('token');
                localStorage.removeItem('alumno_id');

                this.props.history.push("/login");
            })
            .catch(err => console.log(err))

    };

    handleChange = (e) => {
        let {image} = this.state;
        let file = e.target.name;
        image[file] = e.target.files[0];
        this.setState({image})
    };

    checkConvo = () => {
        const url = "http://148.204.63.213:80/IPN-Central-Route/usuario";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };

        axios.get(`${url}/datos/Alumnos`, headers)
            .then((res) => {
                //console.log('res convo',res.data.tabla_solicitudes_seguimientos);
                this.setState({staConvo: res.data.tabla_solicitudes_seguimientos.convocatoria_id});
                axios.get(`${url}/convocatoria/${this.state.staConvo}`, headers)
                    .then((res) => {
                        //console.log('%c info deta convo ', 'color: orange',res.data.tabla_convocatoria);
                        this.setState({infoConvo: res.data.tabla_convocatoria.tipo_movilidad})
                    })
                    .catch(err => console.log('%c error deta convo', 'color: yellow', 'font-size:30px', err))

            })
            .catch(err => console.log('erro de convo', err))
    };

    checkDocs = () => {
        const url = "http://148.204.63.213:80/IPN-Central-Route/";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        let idUser = localStorage.getItem('userId');
        let idAlumno = localStorage.getItem('idAlumno');
        console.log(idAlumno);
        axios.get(`${url}/archivo/fileList/${idAlumno}`, headers)
            .then((res) => {
                console.log('Verificar Docs', res.data);
                this.setState({infoDoc: res.data})
            })
            .catch(err => console.log('error de Doc', err))
    };

    handleSubmit = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //2
        const CartaEspanol = new FormData();
        CartaEspanol.append("archivo", this.state.image['CartaEspanol']);
        CartaEspanol.append("filename", this.state.image['CartaEspanol'].name);
        //3
        const CartaIngles = new FormData();
        CartaIngles.append("archivo", this.state.image['CartaIngles']);
        CartaIngles.append("filename", this.state.image['CartaIngles'].name);
        //4
        const SolicitudMovilidad = new FormData();
        SolicitudMovilidad.append("archivo", this.state.image['SolicitudMovilidad']);
        SolicitudMovilidad.append("filename", this.state.image['SolicitudMovilidad'].name);
        //8
        const CurriculumVitae = new FormData();
        CurriculumVitae.append("archivo", this.state.image['CurriculumVitae']);
        CurriculumVitae.append("filename", this.state.image['CurriculumVitae'].name);
        //9
        const IdentificacionOficial = new FormData();
        IdentificacionOficial.append("archivo", this.state.image['IdentificacionOficial']);
        IdentificacionOficial.append("filename", this.state.image['IdentificacionOficial'].name);
        //10
        const CopiaCurp = new FormData();
        CopiaCurp.append("archivo", this.state.image['CopiaCurp']);
        CopiaCurp.append("filename", this.state.image['CopiaCurp'].name);
        //11
        const CertificadoMedico = new FormData();
        CertificadoMedico.append("archivo", this.state.image['CertificadoMedico']);
        CertificadoMedico.append("filename", this.state.image['CertificadoMedico'].name);
        //12
        const CarnetMedico = new FormData();
        CarnetMedico.append("archivo", this.state.image['CarnetMedico']);
        CarnetMedico.append("filename", this.state.image['CarnetMedico'].name);

        axios.post(`${url}/CartaEspanol`, CartaEspanol, headers)
            .then(res => {
                //console.log(res);
                axios.post(`${url}/CartaIngles`, CartaIngles, headers)
                    .then(res => {
                        //console.log(res);
                        axios.post(`${url}/SolicitudMovilidad`, SolicitudMovilidad, headers)
                            .then(res => {
                                //console.log(res);
                                axios.post(`${url}/CurriculumVitae`, CurriculumVitae, headers)
                                    .then(res => {
                                        //console.log(res);
                                        axios.post(`${url}/IdentificacionOficial`, IdentificacionOficial, headers)
                                            .then(res => {
                                                //console.log(res);
                                                axios.post(`${url}/Otros`, CopiaCurp, headers)
                                                    .then(res => {
                                                        //console.log(res);
                                                        axios.post(`${url}/CertificadoMedico`, CertificadoMedico, headers)
                                                            .then(res => {
                                                                //console.log(res);
                                                                axios.post(`${url}/CarnetMedico`, CarnetMedico, headers)
                                                                    .then(res => {
                                                                        //console.log(res);
                                                                        window.Materialize.toast("Documentación subida exitosamente", 2000);
                                                                        this.props.history.push("/homeAlum")
                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error);
                                                                        console.log(error.code);
                                                                        console.log(error.message);
                                                                        window.Materialize.toast("Error al subir el acrhivo Carrnet Médico, tu conexión es inestable", 2000);
                                                                    })
                                                            })
                                                            .catch(error => {
                                                                console.log(error);
                                                                console.log(error.code);
                                                                console.log(error.message);
                                                                window.Materialize.toast("Error al subir el acrhivo Certificado Médico,tu conexión es inestable", 2000);
                                                            })
                                                    })
                                                    .catch(error => {
                                                        console.log(error);
                                                        console.log(error.code);
                                                        console.log(error.message);
                                                        window.Materialize.toast("Error al subir el acrhivo Otros,tu conexión es inestable ", 2000);
                                                    })
                                            })
                                            .catch(error => {
                                                console.log(error);
                                                console.log(error.code);
                                                console.log(error.message);
                                                window.Materialize.toast("Error al subir el acrhivo de Identificación,tu conexión es inestable ", 2000);
                                            })
                                    })
                                    .catch(error => {
                                        console.log(error);
                                        console.log(error.code);
                                        console.log(error.message);
                                        window.Materialize.toast("Error al subir el acrhivo Curriculum Vitae,tu conexión es inestable ", 2000);
                                    })
                            })
                            .catch(error => {
                                console.log(error);
                                console.log(error.code);
                                console.log(error.message);
                                window.Materialize.toast("Error al subir el acrhivo Solicitud de Movilidad,tu conexión es inestable ", 2000);
                            })
                    })
                    .catch(error => {
                        console.log(error);
                        console.log(error.code);
                        console.log(error.message);
                        window.Materialize.toast("Error al subir el acrhivo Carta de expocición de Motivos en Ingles, tu conexión es inestable", 2000);
                    })
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error al subir el acrhivo Carta de expocición de Motivos en Español, tu conexión es inestable\"", 2000);
            })
    };
    uploadCartaEspanol = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        //alert('Subiendo');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //2
        const CartaEspanol = new FormData();
        CartaEspanol.append("archivo", this.state.image['CartaEspanol']);
        CartaEspanol.append("filename", this.state.image['CartaEspanol'].name);

        axios.post(`${url}/CartaEspanol`, CartaEspanol, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("cartaExposicionMotivosEspanolValido");
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo Carta de expocición de Motivos en Español excede el tamaño maximo\"", 2000);
            })
    };

    uploadCartaIngles = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //3
        const CartaIngles = new FormData();
        CartaIngles.append("archivo", this.state.image['CartaIngles']);
        CartaIngles.append("filename", this.state.image['CartaIngles'].name);

        axios.post(`${url}/CartaIngles`, CartaIngles, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("cartaExposicionMotivosInglesValido");
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo Carta de expocición de Motivos en Ingles excede el tamaño maximo", 2000);
            })
    };
    uploadSolicitudMovilidad = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //4
        const SolicitudMovilidad = new FormData();
        SolicitudMovilidad.append("archivo", this.state.image['SolicitudMovilidad']);
        SolicitudMovilidad.append("filename", this.state.image['SolicitudMovilidad'].name);

        axios.post(`${url}/SolicitudMovilidad`, SolicitudMovilidad, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("solicitudProgramaMovilidadAcademicaValido");
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo Solicitud de Movilidad excede el tamaño maximo", 2000);
            })

    };
    uploadCurriculumVitae = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //8
        const CurriculumVitae = new FormData();
        CurriculumVitae.append("archivo", this.state.image['CurriculumVitae']);
        CurriculumVitae.append("filename", this.state.image['CurriculumVitae'].name);

        axios.post(`${url}/CurriculumVitae`, CurriculumVitae, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("curriculumVitaeValido");
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo Curriculum Vitae excede el tamaño maximo", 2000);
            })
    };
    uploadIdentificacionOficial = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //9
        const IdentificacionOficial = new FormData();
        IdentificacionOficial.append("archivo", this.state.image['IdentificacionOficial']);
        IdentificacionOficial.append("filename", this.state.image['IdentificacionOficial'].name);

        axios.post(`${url}/IdentificacionOficial`, IdentificacionOficial, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("identificacionOficialValido");
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo de Identificación excede el tamaño maximo", 2000);
            })
    };
    uploadCopiaCurp = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };

        //10
        const CopiaCurp = new FormData();
        CopiaCurp.append("archivo", this.state.image['CopiaCurp']);
        CopiaCurp.append("filename", this.state.image['CopiaCurp'].name);

        axios.post(`${url}/Otros`, CopiaCurp, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("identificacionOficialValido");
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo Otros excede el tamaño maximo", 2000);
            })
    };
    uploadCertificado = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //11
        const CertificadoMedico = new FormData();
        CertificadoMedico.append("archivo", this.state.image['CertificadoMedico']);
        CertificadoMedico.append("filename", this.state.image['CertificadoMedico'].name);

        axios.post(`${url}/CertificadoMedico`, CertificadoMedico, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("certificadoMedicoValido");
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo Certificado Médico excede el tamaño maximo", 2000);
            })
    };
    iploadCarnet = () => {
        let {image} = this.state;
        let idAlumno = localStorage.getItem('idAlumno');
        let idUser = localStorage.getItem('userId');
        let token = localStorage.getItem('token');
        console.log(idAlumno);
        //console.log('%c imagen', 'color:yellow', image.length);
        alert('Subiendo');

        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //12
        const CarnetMedico = new FormData();
        CarnetMedico.append("archivo", this.state.image['CarnetMedico']);
        CarnetMedico.append("filename", this.state.image['CarnetMedico'].name);

        axios.post(`${url}/CarnetMedico`, CarnetMedico, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Documentación subida exitosamente", 2000, 'green');
                this.validarDocs("cartillaCarnetSeguroMedicoValido");
                this.props.history.push("/homeAlum")
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error el acrhivo Carrnet Médico excede el tamaño maximo", 2000);
            })
    };

    validarDocs=(Doc)=>{
        //this.setState({saving:true});
       // e.preventDefault();
        let idAlumno=localStorage.getItem('idAlumno');
        //console.log(idAlumno);
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/movilidadDocumentos/updateMetaData`;
        let token = localStorage.getItem('token');
        const head = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        const datos={
            "alumno_id": idAlumno,
            "carta_exposicion_motivos_espanol_valida": Doc==="cartaExposicionMotivosEspanolValido" ? null: this.state.infoDoc.cartaExposicionMotivosEspanolValido,
            "carta_exposicion_motivos_ingles_valida": Doc==="cartaExposicionMotivosInglesValido" ? null: this.state.infoDoc.cartaExposicionMotivosInglesValido,
            "solicitud_programa_movilidad_academica_valida": Doc==="solicitudProgramaMovilidadAcademicaValido" ? null: this.state.infoDoc.solicitudProgramaMovilidadAcademicaValido,
            "curriculum_vitae_valido": Doc==="curriculumVitaeValido" ? null: this.state.infoDoc.curriculumVitaeValido,
            "identificacion_oficial_valida": Doc==="identificacionOficialValido" ? null: this.state.infoDoc.identificacionOficialValido,
            "certificado_medico_valido": Doc==="certificadoMedicoValido" ? null: this.state.infoDoc.certificadoMedicoValido,
            "cartilla_carnet_seguro_medico_valida": Doc==="cartillaCarnetSeguroMedicoValido" ? null: this.state.infoDoc.cartillaCarnetSeguroMedicoValido,

        };
        console.log('Datos', datos);
        axios.post(url, datos, head)
            .then((res)=>{
                console.log('Validar Docs',res);
                this.checkDocs();
                //this.setState({infoDoc:res});
                //this.noificareMail(e);
            })
            .catch(err=> {
                this.setState({saving:false});
                console.log('error al Validar',err);
            });
    };

    componentDidMount() {
        this.checkDocs();
        this.checkConvo();
        //this.validarDocs();
    }

    render() {
        let {infoDoc} = this.state;
        //console.log("InfoConvo"); ------ Para información de la Convocatoria
        //console.log(infoDoc);// ------ Para información de los Docuementos
        return (
            <>
                <NavbarStud
                    logOut={this.LogOut}
                />
                {/*<button onClick={this.sum}> suma</button>*/}
                {/*<div className="container">{console.log('%c infoConvo','color:pink', infoConvo)}*/}
                <div className="container">
                    <Card
                        title="Documentación para la solicitud"
                    >
                        <Table striped={true} className="white z-depth-2">
                            <thead>
                            <tr>
                                <th data-field="id">Documento en formato PDF</th>
                                <th data-field="name">Archivo</th>
                                <th data-field="button">Subir</th>
                                <th data-field="image">Estatus</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1.- Carta de exposición de motivos en español firmada por el alumno</td>
                                {
                                    infoDoc &&
                                    infoDoc.CartaEspanol === true
                                        ?
                                        <>
                                            {
                                                infoDoc.cartaExposicionMotivosEspanolValido === true
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/verde.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.cartaExposicionMotivosEspanolValido === false
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="actualizar"
                                                                type='file'
                                                                name='CartaEspanol'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                                <Button
                                                                    onClick={this.uploadCartaEspanol}
                                                                >
                                                                    subir
                                                                </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/rojo.png" width="25" height="25"/>
                                                            <div className="pt">
                                                                <Modal
                                                                    trigger={
                                                                        <img src="../../images/help.png" width="25" height="25"/>
                                                                    }>
                                                                    <Input
                                                                        required
                                                                        label="Motivos"
                                                                        type='text'
                                                                        name='NotaCe'
                                                                        value={infoDoc.cartaExposicionMotivosEspanolNota}
                                                                    />
                                                                </Modal>
                                                            </div>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.cartaExposicionMotivosEspanolValido === null
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="Examinar"
                                                                type='file'
                                                                name='CartaEspanol'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadCartaEspanol}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/amarillo.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <td style={{textAlign: 'center'}}>
                                                <Input
                                                    required
                                                    label="Examinar"
                                                    type='file'
                                                    name='CartaEspanol'
                                                    onChange={this.handleChange}
                                                    accept='image/x-png,image/jpeg,application/pdf'
                                                />
                                            </td>
                                            < td style={{textAlign: 'center'}}>
                                                <Button
                                                    onClick={this.uploadCartaEspanol}
                                                >
                                                    subir
                                                </Button>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <p>S/D</p>
                                            </td>
                                        </>
                                }

                            </tr>
                            <tr>
                                <td>2.- Carta de exposición de motivos en inglés firmada por el alumno</td>
                                {
                                    infoDoc &&
                                    infoDoc.CartaIngles === true
                                        ?
                                        <>
                                            {
                                                infoDoc.cartaExposicionMotivosInglesValido === true
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/verde.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.cartaExposicionMotivosInglesValido === false
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="actualizar"
                                                                type='file'
                                                                name='CartaIngles'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadCartaIngles}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/rojo.png" width="25" height="25"/>
                                                            <div className="pt">
                                                                <Modal
                                                                    trigger={
                                                                        <img src="../../images/help.png" width="25" height="25"/>
                                                                    }>
                                                                    <Input
                                                                        required
                                                                        label="Motivos"
                                                                        type='text'
                                                                        name='NotaCe'
                                                                        value={infoDoc.cartaExposicionMotivosInglesNota}
                                                                    />
                                                                </Modal>
                                                            </div>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.cartaExposicionMotivosInglesValido === null
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="Examinar"
                                                                type='file'
                                                                name='CartaIngles'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadCartaIngles}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/amarillo.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <td style={{textAlign: 'center'}}>
                                                <Input
                                                    required
                                                    label="Examinar"
                                                    type='file'
                                                    name='CartaIngles'
                                                    onChange={this.handleChange}
                                                    accept='image/x-png,image/jpeg,application/pdf'
                                                />
                                            </td>
                                            < td style={{textAlign: 'center'}}>
                                                <Button
                                                    onClick={this.uploadCartaIngles}
                                                >
                                                    subir
                                                </Button>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <p>S/D</p>
                                            </td>
                                        </>
                                }

                            </tr>
                            <tr>
                                <td>3.- Solicitud de participación en el programa de movilidad académica</td>
                                {
                                    infoDoc &&
                                    infoDoc.SolicitudMovilidad === true
                                        ?
                                        <>
                                            {
                                                infoDoc.solicitudProgramaMovilidadAcademicaValido === true
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/verde.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.solicitudProgramaMovilidadAcademicaValido === false
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="actualizar"
                                                                type='file'
                                                                name='SolicitudMovilidad'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadSolicitudMovilidad}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/rojo.png" width="25" height="25"/>
                                                            <div className="pt">
                                                                <Modal
                                                                    trigger={
                                                                        <img src="../../images/help.png" width="25" height="25"/>
                                                                    }>
                                                                    <Input
                                                                        required
                                                                        label="Motivos"
                                                                        type='text'
                                                                        name='NotaCe'
                                                                        value={infoDoc.solicitudProgramaMovilidadAcademicaNota}
                                                                    />
                                                                </Modal>
                                                            </div>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.solicitudProgramaMovilidadAcademicaValido === null
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="Examinar"
                                                                type='file'
                                                                name='SolicitudMovilidad'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadSolicitudMovilidad}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/amarillo.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <td style={{textAlign: 'center'}}>
                                                <Input
                                                    required
                                                    label="Examinar"
                                                    type='file'
                                                    name='SolicitudMovilidad'
                                                    onChange={this.handleChange}
                                                    accept='image/x-png,image/jpeg,application/pdf'
                                                />
                                            </td>
                                            < td style={{textAlign: 'center'}}>
                                                <Button
                                                    onClick={this.uploadSolicitudMovilidad}
                                                >
                                                    subir
                                                </Button>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <p>S/D</p>
                                            </td>
                                        </>
                                }

                            </tr>
                            <tr>
                                <td>4.-Curriculum Vitae (máximo dos cuartillas)</td>
                                {
                                    infoDoc &&
                                    infoDoc.CurriculumVitae === true
                                        ?
                                        <>
                                            {
                                                infoDoc.curriculumVitaeValido === true
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/verde.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.curriculumVitaeValido === false
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="actualizar"
                                                                type='file'
                                                                name='CurriculumVitae'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadCurriculumVitae}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/rojo.png" width="25" height="25"/>
                                                            <div className="pt">
                                                                <Modal
                                                                    trigger={
                                                                        <img src="../../images/help.png" width="25" height="25"/>
                                                                    }>
                                                                    <Input
                                                                        required
                                                                        label="Motivos"
                                                                        type='text'
                                                                        name='NotaCe'
                                                                        value={infoDoc.curriculumVitaeNota}
                                                                    />
                                                                </Modal>
                                                            </div>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.curriculumVitaeValido === null
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="Examinar"
                                                                type='file'
                                                                name='CurriculumVitae'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadCurriculumVitae}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/amarillo.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <td style={{textAlign: 'center'}}>
                                                <Input
                                                    required
                                                    label="Examinar"
                                                    type='file'
                                                    name='CurriculumVitae'
                                                    onChange={this.handleChange}
                                                    accept='image/x-png,image/jpeg,application/pdf'
                                                />
                                            </td>
                                            < td style={{textAlign: 'center'}}>
                                                <Button
                                                    onClick={this.uploadCurriculumVitae}
                                                >
                                                    subir
                                                </Button>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <p>S/D</p>
                                            </td>
                                        </>
                                }

                            </tr>
                            <tr>
                                <td>5.-Identificación oficial vigente (INE, Pasaporte, Cartilla de S.M. liberada)</td>
                                {
                                    infoDoc &&
                                    infoDoc.IdentificacionOficial === true
                                        ?
                                        <>
                                            {
                                                infoDoc.identificacionOficialValido === true
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/verde.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.identificacionOficialValido === false
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="actualizar"
                                                                type='file'
                                                                name='IdentificacionOficial'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadIdentificacionOficial}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/rojo.png" width="25" height="25"/>
                                                            <div className="pt">
                                                                <Modal
                                                                    trigger={
                                                                        <img src="../../images/help.png" width="25" height="25"/>
                                                                    }>
                                                                    <Input
                                                                        required
                                                                        label="Motivos"
                                                                        type='text'
                                                                        name='NotaCe'
                                                                        value={infoDoc.identificacionOficialNota}
                                                                    />
                                                                </Modal>
                                                            </div>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.identificacionOficialValido === null
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="Examinar"
                                                                type='file'
                                                                name='IdentificacionOficial'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadIdentificacionOficial}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/amarillo.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <td style={{textAlign: 'center'}}>
                                                <Input
                                                    required
                                                    label="Examinar"
                                                    type='file'
                                                    name='IdentificacionOficial'
                                                    onChange={this.handleChange}
                                                    accept='image/x-png,image/jpeg,application/pdf'
                                                />
                                            </td>
                                            < td style={{textAlign: 'center'}}>
                                                <Button
                                                    onClick={this.uploadIdentificacionOficial}
                                                >
                                                    subir
                                                </Button>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <p>S/D</p>
                                            </td>
                                        </>
                                }
                            </tr>
                            <tr>
                                <td>6.-Certificado médico</td>
                                {
                                    infoDoc &&
                                    infoDoc.CertificadoMedico === true
                                        ?
                                        <>
                                            {
                                                infoDoc.certificadoMedicoValido === true
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/verde.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.certificadoMedicoValido === false
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="actualizar"
                                                                type='file'
                                                                name='CertificadoMedico'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadCertificado}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/rojo.png" width="25" height="25"/>
                                                            <div className="pt">
                                                                <Modal
                                                                    trigger={
                                                                        <img src="../../images/help.png" width="25" height="25"/>
                                                                    }>
                                                                    <Input
                                                                        required
                                                                        label="Motivos"
                                                                        type='text'
                                                                        name='NotaCe'
                                                                        value={infoDoc.certificadoMedicoNota}
                                                                    />
                                                                </Modal>
                                                            </div>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.certificadoMedicoValido === null
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="Examinar"
                                                                type='file'
                                                                name='CertificadoMedico'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.uploadCertificado}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/amarillo.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <td style={{textAlign: 'center'}}>
                                                <Input
                                                    required
                                                    label="Examinar"
                                                    type='file'
                                                    name='CertificadoMedico'
                                                    onChange={this.handleChange}
                                                    accept='image/x-png,image/jpeg,application/pdf'
                                                />
                                            </td>
                                            < td style={{textAlign: 'center'}}>
                                                <Button
                                                    onClick={this.uploadCertificado}
                                                >
                                                    subir
                                                </Button>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <p>S/D</p>
                                            </td>
                                        </>
                                }
                            </tr>
                            <tr>
                                <td>7.-Carnet médico y SGMV</td>
                                {
                                    infoDoc &&
                                    infoDoc.CarnetMedico === true
                                        ?
                                        <>
                                            {
                                                infoDoc.cartillaCarnetSeguroMedicoValido === true
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/verde.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.cartillaCarnetSeguroMedicoValido === false
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="actualizar"
                                                                type='file'
                                                                name='CarnetMedico'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.iploadCarnet}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/rojo.png" width="25" height="25"/>
                                                            <div className="pt">
                                                                <Modal
                                                                    trigger={
                                                                        <img src="../../images/help.png" width="25" height="25"/>
                                                                    }>
                                                                    <Input
                                                                        required
                                                                        label="Motivos"
                                                                        type='text'
                                                                        name='NotaCe'
                                                                        value={infoDoc.cartillaCarnetSeguroMedicoNota}
                                                                    />
                                                                </Modal>
                                                            </div>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                infoDoc.cartillaCarnetSeguroMedicoValido === null
                                                    ?
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Input
                                                                required
                                                                label="Examinar"
                                                                type='file'
                                                                name='CarnetMedico'
                                                                onChange={this.handleChange}
                                                                accept='image/x-png,image/jpeg,application/pdf'
                                                            />
                                                        </td>
                                                        < td style={{textAlign: 'center'}}>
                                                            <Button
                                                                onClick={this.iploadCarnet}
                                                            >
                                                                subir
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <img src="../../images/amarillo.png" width="25" height="25"/>
                                                        </td>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            <td style={{textAlign: 'center'}}>
                                                <Input
                                                    required
                                                    label="Examinar"
                                                    type='file'
                                                    name='CarnetMedico'
                                                    onChange={this.handleChange}
                                                    accept='image/x-png,image/jpeg,application/pdf'
                                                />
                                            </td>
                                            < td style={{textAlign: 'center'}}>
                                                <Button
                                                    onClick={this.iploadCarnet}
                                                >
                                                    subir
                                                </Button>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <p>S/D</p>
                                            </td>
                                        </>
                                }
                            </tr>

                            </tbody>
                        </Table>
                        <div className="pt">
                            {/*<Button
              onClick={this.handleSubmit}
              >
              subir
              </Button>*/}
                        </div>
                    </Card>

                </div>
            </>

        )

    }
}

export default AddDocStud
import React, {Component} from 'react'
import NavbarStud from "./NavbarStud";
import axios from "axios";
import {Row, Col, Input, Button, Card} from 'react-materialize'

class AddPhotoStud extends Component{
    state= {

        Fotografia: "Null",
    };

  LogOut = () => {

    let token=localStorage.getItem('token');
    //console.log('entró al logout',token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(url, head)
      .then(res => {
        console.log('res de logout', res);


        localStorage.removeItem('profile');
        localStorage.removeItem('userName');

        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('alumno_id');

        this.props.history.push("/login");
      })

      .catch(err=>console.log(err))

  };

  checkPhotograph=()=>{
      let idUser=localStorage.getItem('userId');
      let token=localStorage.getItem('token');
      let idAlumno=localStorage.getItem('idAlumno');
      console.log(' idUser',idUser);
      console.log(' idAlumno',idAlumno);
      //console.log('token',token);

      const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/FotoJpg500x500/${idAlumno}`;

      const head = {
          headers: {
              "Accept": "application/json",
              "HR-TOKEN-APPID": 2,
              "HR-APP-VERSION": "1.0.0",
              "HR-APP-TYPE": "WEB",
              "HR-TOKEN-SESSION": token,
              //"Content-Type": "application/json",
          }
      };

      axios.get(url, head)
          .then(res => {
              console.log('Photograph',res);
              this.setState({Fotografia:res.data.b64File});
              //console.log('Photograph',this.state.Fotografia);
          })
          .catch(err=>console.log('Photograph error',err));

  };

  handleChange = (event) => {
    this.setState({ image: event.target.files[0] })
  };

  UpLoadImage= ()=>{
    let idUser=localStorage.getItem('userId');
    let token=localStorage.getItem('token');
    let idAlumno=localStorage.getItem('idAlumno');

    //console.log(' idUser',idUser);
    //console.log('token',token);
    const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Personales/FotoJpg500x500`;

    const headers = {
      headers: {
        'HR-TOKEN-APPID': 2,
        'Accept': 'application/json',
        'HR-APP-TYPE': 'WEB',
        'HR-APP-VERSION': '1.0.0',
        'HR-TOKEN-SESSION': token,
        'Content-Type': 'multipart/form-data'
      }
    };

    const formData = new FormData();

    formData.append("archivo", this.state.image);
    formData.append("filename", this.state.imageName);

    //console.log("image", this.state.image);
    //console.log("fileName", this.state.image.name);
    //console.log('>> formData >> ', formData);


    axios.post(url, formData, headers)
      .then(res => {
        console.log(res.status);
         if(res.status===200) {
            window.Materialize.toast("Fotografia subida exitosamente", 2000);
            console.log('-------->', res);
           this.props.history.push("/homeAlum")
         }
      })
      .catch(error => {
        console.log(error);
        console.log(error.code);
        console.log(error.message)
      })
  };
    downLoad ()
    {
        //openPDF(()=>Pdf);
    };
    componentWillMount() {
        this.checkPhotograph();
    };

    render() {
        let {Fotografia } = this.state;
        //console.log(Fotografia);
    return(
      <>
        <NavbarStud
          logOut={this.LogOut}
        />
          <h5 className="txt-ipn"> Fotografía</h5>
          <div className="container pt" >
          <Card>
        <Row>
            <Col m={6}>
                <br/>
                <br/>
                <br/>
                <Input
                    m={12}
                    label="examinar"
                    type='file'
                    name='image'
                    onChange={this.handleChange}
                    accept='image/x-png,image/jpeg/jpg'
                />
                <p>1.-Elegir Fotografía </p>
            </Col>
            <Col m={6}>
                <br/>
                <img src={this.state&&this.state.image ? URL.createObjectURL(this.state.image) : "data:image/jpg;base64," + Fotografia } width="150" alt=""/>

            </Col>
        </Row>
        <Row>
            <Col m={6}>
                <Button
                    floating
                    large
                    className="btn-ipn"
                    waves="red"
                    icon="cloud_upload"
                    onClick={this.UpLoadImage}
                />
                <p>2.-Subir Fotografía </p>
            </Col>
        </Row>
        </Card>
       </div>
      </>
    )
  }
}

export default AddPhotoStud


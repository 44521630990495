import React from 'react'

import {Button, Card, Table} from 'react-materialize'
import {Link} from "react-router-dom";

const TableAddreStud= ({data}) =>{
  return(
    <Card>
        <strong>Dirección </strong>
        <br/>
        <Table  responsive={true} className="white">
        <tbody>
        <tr>
          <td>
            {
              `Calle ${data.calle}, No. ${data.no_exterior}, Colonia ${data.colonia},
              Municipio ${data.municipio_delegacion}, CP ${data.codigo_postal}`
            }
          </td>
          </tr>
        </tbody>
      </Table>
        <br/>
        <Link  to="/homeAlum">
            <Button
                name="modificar"
            >        regresar
            </Button>
        </Link>
    </Card>
  );
};

export default TableAddreStud;

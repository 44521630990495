import React, {Component} from 'react';
import axios from 'axios';
import NavbarStud from "./NavbarStud";
import {Link, Redirect} from "react-router-dom";
import {Button, Card, Col, Input, Row} from "react-materialize";

class HomeStud extends Component{

  state={
    session:false, token : localStorage.getItem('token'), idAlumno : localStorage.getItem('idAlumno'), idSolicitud : localStorage.getItem('idSolicitud'), Ficha:false
  };

  LogOut = () => {

    let token=localStorage.getItem('token');
    console.log('entró al logout',token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(url, head)
      .then(res => {
        console.log('res de logout', res);


        localStorage.removeItem('profile');
        localStorage.removeItem('userName');

        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('alumno_id');

        this.props.history.push("/login");
      })

      .catch(err=>console.log(err))

  };

  sessionActive=()=>{
    let {token}= this.state;
    const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

    const newHead = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(newUrl, newHead)
      .then(res => {
        console.log('Datos',res);
        localStorage.setItem('idAlumno', res.data.alumno_id);
        console.log('Alumno', res.data.alumno_id);
      })
      .catch((err)=>{
        console.log("la sesion acabo");
        this.setState({session:true})
      })
  };

  checkInfoDae = () => {
    let idAlumno=localStorage.getItem('idAlumno');
    //console.log(idAlumno);
    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/Alumnos";
    const url2 = "http://148.204.63.213:80/IPN-Central-Route/preregistro/serviceDaeFull";
    const url3 = `http://148.204.63.213:80/IPN-Central-Route/archivo/download/FotoJpg500x500/${idAlumno}`;

      let headers2 = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "Content-Type": "application/json",
      }
    };

    let token = localStorage.getItem('token');
    let{idSolicitud}= this.state;
    let headers = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
    axios.get(url,headers)
        .then((res)=>{
          console.log('Verificar Datos',res.data.tabla_solicitudes_seguimientos.solicitud_id);
          localStorage.setItem('idSolicitud', res.data.tabla_solicitudes_seguimientos.solicitud_id);
          if(typeof res.data.tabla_solicitudes_seguimientos.solicitud_id !== "undefined"){this.setState({Ficha:true});}
          //console.log(idSolicitud);
          const boleta = {
            "boleta": res.data.tabla_alumnos.boleta
          };
          //console.log('id',boleta);
          /*axios.get(url3, headers)
                .then(res_2 => {
                    //console.log('Photograph',res_2.data.b64File);
                    //console.log('Solicitud_id', res.data.tabla_solicitudes_seguimientos.solicitud_id);
                    if(typeof res_2.data.b64File !== "undefined" && res.data.tabla_solicitudes_seguimientos.solicitud_id !== "undefined" && typeof res_2.data.b64File !== "Null" && res.data.tabla_solicitudes_seguimientos.solicitud_id !== "Null")
                    {
                        this.setState({Ficha:true});
                        //console.log('Ficha',this.state.Ficha);
                    }
                    else
                    {
                        this.setState({Ficha:false});
                        console.log('Error ficha',this.state.Ficha);
                    }
                })
                .catch(err=>console.log('Photograph error',err));*/

            axios.post(url2,boleta ,headers2)
              .then((res)=> {
                console.log('INFO DAE', res);
                this.setState({infoDatos:res.data})
              })
              .catch((err)=>{
                console.log('error DAE_2',err)
              })
        })
        .catch(err=>console.log('error de DAE',err));
  };

  update = () => {
    let token = localStorage.getItem('token');
    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/actualizar/Alumnos";

    let Datos_2={
      "unidad_academica_ipn_id": 267
    };

    let headers = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
    axios.put(url, Datos_2, headers)
        .then((res)=>{
          console.log('Actualización',res);
        })
        .catch(err=>console.log('Error-Actualizar',err));

  };
  componentDidMount() {
    this.sessionActive();
    this.checkInfoDae();
    //this.update();
    //this.checkPhotograph();
  }

  render() {
    let {session, infoDatos, Ficha}= this.state;
    //let UserName= localStorage.getItem('userName');
    //{console.log('Paso infoDae',infoDatos);}
    return(
      <div>
        <NavbarStud
          logOut={this.LogOut}
        />
        {/*verifica que la sesión esta activa y si no te redirecciona al Home*/}
        {
          session===true?
            <>
              <Redirect to="/login"/>
            </>
            :""
        }
        <h5 className="txt-ipn"> DATOS GENERALES  {/*UserName*/} </h5>

          <div className="container pt" >
              {     infoDatos&&
                          <Card className="z-depth-3">
                              <form>
                                <Row>
                                      <Input
                                          id="nombres"
                                          l={3}
                                          s={10}
                                          required
                                          validate
                                          type="text"
                                          name="nombres"
                                          value={infoDatos.NOMBRE}
                                          label={"Nombre (s)"}
                                      />
                                      <Input
                                          l={3}
                                          s={10}
                                          name="ap_paterno"
                                          value={infoDatos.PATERNO}
                                          label={"Apellido Paterno"}
                                          required
                                          validate
                                          type="text"
                                      />
                                      <Input
                                          l={3}
                                          s={10}
                                          name="ap_materno"
                                          value={infoDatos.MATERNO}
                                          label={"Apellido Materno"}
                                          required
                                          validate
                                          type="text"
                                      />
                                    <Input
                                        l={2}
                                        s={10}
                                        name="boleta"
                                        value={infoDatos.BOLETA}
                                        label={"Boleta"}
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        l={1}
                                        s={10}
                                        name="estatus"
                                        value={infoDatos.TIPO_ALUMNO}
                                        label={"Estatus"}
                                        required
                                        validate
                                        type="text"
                                    />
                                  </Row>
                                  <Row>
                                    <Input
                                        id="curp"
                                        l={3}
                                        s={12}
                                        name="curp"
                                        value={infoDatos.CURP}
                                        onChange={this.handleChange}
                                        label={"CURP"}
                                        required
                                        validate
                                        type="text"
                                    />
                                      <Input
                                          l={5}
                                          s={12}
                                          name="carrera"
                                          value={infoDatos.CARRERA}
                                          label={"Carrera"}
                                          required
                                          validate
                                          type="text"
                                      />
                                      <Input
                                          l={1}
                                          s={12}
                                          name="promedio"
                                          value={infoDatos.PROMEDIO}
                                          label={"Promedio"}
                                          required
                                          validate
                                          type="text"
                                      />
                                      <Input
                                          l={1}
                                          s={12}
                                          name="avance"
                                          value={infoDatos.AVANCE}
                                          label={"Avance"}
                                          required
                                          validate
                                          type="text"
                                      />
                                    <Input
                                        l={1}
                                        s={12}
                                        name="semestre"
                                        value={infoDatos.MAXIMO_SEMESTRE_CURSADO}
                                        label={"Semestre"}
                                        required
                                        validate
                                        type="text"
                                    />
                                    <Input
                                        l={1}
                                        s={12}
                                        name="nivel"
                                        value={infoDatos.NIVEL}
                                        label={"Nivel"}
                                        required
                                        validate
                                        type="text"
                                    />
                                  </Row>
                              </form>
                          </Card>
              }
          </div>
        <Row className="container">
          <Col m={6}>
            <Link to="/actualizaDatosAlum">
              <Button
                  floating
                  large
                  className="btn-ipn"
                  waves="light"
                  icon="person"
              />
            </Link>
            <p>1.- Completar Datos</p>
          </Col>
          <Col m={6}>
            <Link to="/agregarDocAlum">
              <Button
                  floating
                  large
                  className="btn-ipn"
                  waves="red"
                  icon="recent_actors"
              />
            </Link>
            <p>2.- Subir Documentos</p>
          </Col>
        </Row>
        <Row className="container">
          <Col m={6}>
            <Link to="/cambiarFotoAlum">
              <Button hidden={true}
                      floating
                      large
                      className="btn-ipn"
                      waves="red"
                      icon="cloud_upload"
              />
            </Link>
            <p>3.- Subir Fotografía</p>
          </Col>
          <Col m={6}>
            <Link to="/registroOption">
              <Button
                  floating
                  large
                  className="btn-ipn"
                  waves="red"
                  //icon="add_a_photo"
                  icon="account_balance"
              />
            </Link>
            <p>4.- Selección de Universidades</p>
          </Col>
        </Row>
          {
              Ficha ===true ?
              <>
              <Row className="container">
                  <Link target='_blank' to="/pdf">
                      <Button hidden={true}
                              floating
                              large
                              className="btn-ipn"
                              waves="red"
                          //icon="picture_in_picture"
                              icon="assignment_ind"
                          //onClick={this.downLoad}
                      />
                  </Link>
                  <p>5.- Generar Ficha de registro</p>
              </Row>
              </>
                  :""
          }
      </div>
    );

  }

}

export default HomeStud

import React,{Component} from 'react'

import NavbarCCA from "./NavbarCCA";
import axios from "axios";
import {Link, Redirect} from "react-router-dom";
import {Button, Card, Col, Input, Row} from "react-materialize";

class HomeCCA extends Component{
  state={
    session:false, token : localStorage.getItem('token')
  };

  sessionActive=()=>{
    let {token}= this.state;
    const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

    const newHead = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
  console.log(token);
    axios.get(newUrl, newHead)
      .then(res => {
        console.log("Token activo")
      })
      .catch((err)=>{
        console.log("la sesion acabo")
        this.setState({session:true})
      })
  };

  update=()=>{
    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/actualizar/Seguimientos";

    let Datos_2={
      "alumno_id": 449,
      "cat_estatus_solicitud_id": 387,
      "convocatoria_id": 2,
      "fase1_solicitud": true,
      "fase2_evaluacion": true,
      "fase3_postulacion": false,
      "fase4_aceptado": false,
      "fase5_fin_estancia": false,
      "fase6_liberado": false,
      "observaciones": "Segund Fase"
    };
    let Datos={
      "instituto_1": 202,
      "instituto_2": 327,
      "instituto_3": 229,
      "no_opcion": 2,
      "solicitud_id": 1123
    };
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
    axios.put(url, Datos_2, headers)
        .then((res)=>{
          console.log('Actualización',res);
        })
        .catch(err=>console.log('Error-Actualizar',err));

  };

  // axios.get("http:148.204.64.242:80//IPN-Central-Route/usuario/datos/runAsignacion",head)

  update2=()=>{
    const url = "http://148.204.63.213:80/IPN-Central-Route/preregistro/updateFace";

    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
    axios.get(url, headers)
        .then((res)=>{
          console.log('Actualización',res);
        })
        .catch(err=>console.log('Error-Actualizar',err));

  };
  componentDidMount() {
    this.sessionActive();
    //this.update2();
  }

  LogOut = () => {

    let token=localStorage.getItem('token')
    console.log('entró al logout',token)

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout"
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(url, head)
      .then(res => {
        localStorage.removeItem('profile');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('alumno_id');

        this.props.history.push("/login");
      })
    .catch(err=>console.log(err))
  };


  getStudent=()=>{
    let token=localStorage.getItem('token')
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get("http://148.204.63.213:80/IPN-Central-Route/usuario/datos/InformacionAlumnos",head )
      .then((res)=>{
        console.log("--->",res.data)
        this.setState({tablez:res.data.informacion_alumnos})
      })
      .catch(err=>console.log(err))

  };

  render() {
    let {session, infoDatos, Ficha}= this.state;
    //let UserName= localStorage.getItem('userName');
    //{console.log('Paso infoDae',infoDatos);}
    return(
        <div>
          <NavbarCCA
              logOut={this.LogOut}
          />
          {/*verifica que la sesión esta activa y si no te redirecciona al Home*/}
          {
            session===true?
                <>
                  <Redirect to="/login"/>
                </>
                :""
          }
          <h5 className="txt-ipn"> OPCIONES GENERALES  {/*UserName*/} </h5>

          {/*<div className="container pt" >
            {     infoDatos&&
            <Card className="z-depth-3">
              <form>
                <Row>
                  <Input
                      id="nombres"
                      l={3}
                      s={10}
                      required
                      validate
                      type="text"
                      name="nombres"
                      value={infoDatos.NOMBRE}
                      label={"Nombre (s)"}
                  />
                  <Input
                      l={3}
                      s={10}
                      name="ap_paterno"
                      value={infoDatos.PATERNO}
                      label={"Apellido Paterno"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={3}
                      s={10}
                      name="ap_materno"
                      value={infoDatos.MATERNO}
                      label={"Apellido Materno"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={2}
                      s={10}
                      name="boleta"
                      value={infoDatos.BOLETA}
                      label={"Boleta"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={1}
                      s={10}
                      name="estatus"
                      value={infoDatos.TIPO_ALUMNO}
                      label={"Estatus"}
                      required
                      validate
                      type="text"
                  />
                </Row>
                <Row>
                  <Input
                      id="curp"
                      l={3}
                      s={12}
                      name="curp"
                      value={infoDatos.CURP}
                      onChange={this.handleChange}
                      label={"CURP"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={5}
                      s={12}
                      name="carrera"
                      value={infoDatos.CARRERA}
                      label={"Carrera"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={1}
                      s={12}
                      name="promedio"
                      value={infoDatos.PROMEDIO}
                      label={"Promedio"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={1}
                      s={12}
                      name="avance"
                      value={infoDatos.AVANCE}
                      label={"Avance"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={1}
                      s={12}
                      name="semestre"
                      value={infoDatos.MAXIMO_SEMESTRE_CURSADO}
                      label={"Semestre"}
                      required
                      validate
                      type="text"
                  />
                  <Input
                      l={1}
                      s={12}
                      name="nivel"
                      value={infoDatos.NIVEL}
                      label={"Nivel"}
                      required
                      validate
                      type="text"
                  />
                </Row>
              </form>
            </Card>
            }
          </div>*/}
          <Row className="container">
            <Col m={6}>
              <Link to="/preregistrosCCA">
                <Button
                    floating
                    large
                    className="btn-ipn"
                    waves="light"
                    icon="person"
                />
              </Link>
              <p>1.- Pre-registros</p>
            </Col>
            <Col m={6}>
              <Link to="/studentIPNCCA">
                <Button
                    floating
                    large
                    className="btn-ipn"
                    waves="red"
                    icon="recent_actors"
                />
              </Link>
              <p>2.- Alumnos Postulados (Documentación)</p>
            </Col>
          </Row>
          {/*<Row className="container">
            <Col m={6}>
              <Link to="/studentIPNCCA">
                <Button hidden={true}
                        floating
                        large
                        className="btn-ipn"
                        waves="red"
                        icon="cloud_upload"
                />
              </Link>
              <p>3.-Alumnos Postulados (Docuemntación)</p>
            </Col>
            <Col m={6}>
              <Link to="/seleccionesCCA">
                <Button
                    floating
                    large
                    className="btn-ipn"
                    waves="red"
                    //icon="add_a_photo"
                    icon="account_balance"
                />
              </Link>
              <p>4.- Ver Solicitud de Institutos (general)</p>
            </Col>
          </Row>*/}
        </div>
    );

  }
}

export default HomeCCA
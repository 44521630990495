import React from 'react';

import './App.css';
import Router from "./Router";

function App() {
  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;

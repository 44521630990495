import React,{Component} from 'react'
import {Input, Row, Button, Col, Card} from 'react-materialize'
import NavbarStud from "./NavbarStud";
import axios from "axios";
import {Redirect} from "react-router-dom";

class UpdateProfileStud extends Component{
    constructor(props){
        super(props);
        this.guardar = this.guardar.bind(this);
        this.handleformOp = this.handleformOp.bind(this);
    }
    state={

        image: [], solicitudes_institutos:false ,session:false, token : localStorage.getItem('token'),
        form:{
            tipoMovilidadN1: '',
            tipoMovilidadN2: '',
            tipoMovilidadN3: '',
            tipoMovilidadI1: '',
            tipoMovilidadI2: '',
            tipoMovilidadI3: '',
            tipoMov: 1
        }
    };

  LogOut = () => {

    let token=localStorage.getItem('token');
    console.log('entró al logout',token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(url, head)
      .then(res => {
         console.log('res de logout', res);
        localStorage.removeItem('profile');
        localStorage.removeItem('userName');

        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('alumno_id');

        this.props.history.push("/login");
      })

      .catch(err=>console.log(err))

  };
    sessionActive=()=>{
        let {token}= this.state;
        const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

        const newHead = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(newUrl, newHead)
            .then(res => {
               console.log("Token activo",res)
            })
            .catch((err)=>{
                console.log("la sesion acabo");
                this.setState({session:true})
            })
    };
    checkInternacionales=()=>{

        const url = "http://148.204.63.213:80/IPN-Central-Route";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}/usuario/institutos/Internacionales`,headers)
            .then((res)=> {
                //console.log('Verificar Institutos Internacionales', res.data);
                this.setState({infoInt:res.data}, ()=>this.setOptionNames())
            })
            .catch(err=>console.log('error de Institutos Internacionales',err));
    };
    checkNacionales=()=>{
        const url = "http://148.204.63.213:80/IPN-Central-Route";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}/usuario/institutos/Nacionales`,headers)
            .then((res)=> {
                //console.log('Verificar Institutos Nacionales', res.data);
                this.setState({infoNac:res.data}, ()=>this.setOptionNames())

            })
            .catch(err=>console.log('error de Institutos Nacionales',err));
    };

createMenuNac=(infoNac,opc)=> {
return(
        <select
            name={"tipoMovilidadN"+opc}
            className="browser-default conEm"
            onChange={(e) => this.handleformOp(e)}>
            <option value={-1} key={-1} selected >Selecciona Universidad</option>
            {
               infoNac&& infoNac.institutos_nacionales&&
               infoNac.institutos_nacionales.filter(u => u.cat_entidad_federativa_id == this.state.form['menuEstados'+opc]).map(university=>{
                   //console.log('university......',university);
                   return(
                       <option value={university.id} key={university.id}> {university.nombre_instituto}</option>
                   );
               })
            }
        </select>
)
    };
    createMenuInt=(infoInt, opc)=> {
        //console.log(infoInt.institutos_internacionales);

        return(
            <select
                name={"tipoMovilidadI"+opc}
                className="browser-default"
                //value={this.state.form["tipoMovilidadI"+opc]}
                onChange={(e) => this.handleformOp(e)}>

                <option value={-1} key={-1} selected>Selecciona Universidad</option>
                {
                    infoInt&& infoInt.institutos_internacionales&&
                    infoInt.institutos_internacionales.filter(u => u.cat_nacion_id == this.state.form['menuNaciones'+opc]).map(university=>{
                        return(
                            <option value={university.id} key={university.id}> {university.nombre_instituto}</option>
                        )
                    })
                }
            </select>

        );
    };
    createMenuNac_2=(estados ,opc)=> {
        //console.log('------------',estados);
        return(
            <select
                name={"menuEstados"+opc}
                className="browser-default conEm"
                onChange={(e) => this.handleformOp(e)}>
                <option value={-1} key={-1} selected >Estado</option>
                {
                    estados&&
                    estados.map(estado=>{
                        //console.log('estados -------',estado.objeto_id);
                        return(
                            <option value={estado.objeto_id} key={estado.objeto_id}> {estado.descripcion_objeto}</option>
                        );
                    })
                }
            </select>
        )
    };
    createMenuInt_2=(naciones, opc)=> {
        return(
            <select
                name={"menuNaciones"+opc}
                className="browser-default"
                //value={this.state.form["tipoMovilidadI"+opc]}
                onChange={(e) => this.handleformOp(e)}>
                <option value={-1} key={-1} selected>País</option>
                {
                    naciones&&
                    naciones.map(nacion=>{
                        return(
                            <option value={nacion.objeto_id} key={nacion.objeto_id}> {nacion.descripcion_objeto}</option>
                        )
                    })
                }
            </select>
        )
    };

nuevaInst=(idSolicitud,opc1, opc2, opc3, end)=> {

    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/solicitud/seguimiento/instituto";
    let token = localStorage.getItem('token');
    let headers = {
        headers: {
            "Accept": "application/json",
            "HR-TOKEN-APPID": 2,
            "HR-APP-VERSION": "1.0.0",
            "HR-APP-TYPE": "WEB",
            "HR-TOKEN-SESSION": token,
            "Content-Type": "application/json",
        }
    };
    console.log('estado',this.state.form);
    var comvo;

        comvo = {
            "solicitud_id": Number(idSolicitud),
            "instituto_1": Number(opc1),
            "instituto_2": Number(opc2),
            "instituto_3": Number(opc3),
            "no_opcion":  " "
        };

    console.log('convo',comvo);
    axios.post(url, comvo, headers)
        .then((res)=>{
            console.log('entro a registro Solicitud');
            window.Materialize.toast('Datos subidos correctamente',1500,'green');
            console.log("res",res);
            if (end){this.props.history.push("/homeAlum")}
            //this.state.form.tipoMovilidadN1 <= 0|
        })
        .catch((err)=>{
            console.log('entro a registro Solicitud');
            console.log("Error al subir datos Solicitud",err)
        })
    //marco.a.ramirez.s@gmail.com {this.nuevaComv()}
};
    checkInfoConvoI = () =>{

        const url="http://148.204.63.213:80/IPN-Central-Route/usuario/convocatoria/";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}1`,headers)
            .then((res)=>{
                //window.Materialize.toast('Conv',1500);
                this.setState({infoConvoI:res.data});
                //console.log("Convocatira",res.data);
            })
            .catch((err)=>{
                console.log("Conv",err)
            })
    };
    checkInfoConvoN = () =>{

        const url="http://148.204.63.213:80/IPN-Central-Route/usuario/convocatoria/";
        let token = localStorage.getItem('token');
        let headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}2`,headers)
            .then((res)=>{
                //window.Materialize.toast('Conv',1500);
                this.setState({infoConvoN:res.data});
                //console.log("Convocatira",res.data);
            })
            .catch((err)=>{
                console.log("Conv",err)
            })
    };
    getCatalogs = ()=> {
        const token = localStorage.getItem('token');
        const url = `http://148.204.63.213:80/IPN-Central-Route/catalogos/`;
        //console.log('%c token ', 'color: green', token);
        const headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        //Peticición para listar   ESTADOS 4
        axios.get(`${url}/4`, headers)
            .then((res) => {
                //console.log('%c catalogos 4 ESTADOS', 'color: pink', res.data.lista_catalogo_contenido);
                this.setState({catEnt: res.data.lista_catalogo_contenido})
            })
            .catch((err) => {
                console.log('err catalogos', err)
            });
        //Peticición para listar NACIONES 3
        axios.get(`${url}/3`, headers)
            .then((res) => {
                //console.log('%c catalogos 3 naciones', 'color: yellow', res.data.lista_catalogo_contenido);
                this.setState({catNac: res.data.lista_catalogo_contenido})
            })
            .catch((err) => {
                console.log('err catalogos', err)
            });
    };
    nuevoSeguimiento = () => {
        if (this.state.form.tipoMov == 1) {
            if (!this.state.form.tipoMovilidadN1 || this.state.form.tipoMovilidadN1<=0 || !this.state.form.tipoMovilidadN2 ||this.state.form.tipoMovilidadN2<=0
                || !this.state.form.tipoMovilidadN3 || this.state.form.tipoMovilidadN3<=0 ) {
               window.Materialize.toast('Selecione sus 3 opciones antes de continuar', 1500);
               return;
            }
        }
       else
           {
               if (!this.state.form.tipoMovilidadI1 || this.state.form.tipoMovilidadI1<=0 ||  !this.state.form.tipoMovilidadI2 || this.state.form.tipoMovilidadI2<=0
                   || !this.state.form.tipoMovilidadI3 || this.state.form.tipoMovilidadI3<=0) {
                   window.Materialize.toast('Selecione sus 3 opciones antes de continuar', 1500);
                   return;
               }
           }
            let idUser=localStorage.getItem('userId');
            let idAlumno=localStorage.getItem('idAlumno');

            console.log('User',idUser);
            console.log('Alumno',idAlumno);
            const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/solicitud/seguimiento/nuevo";
        //const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/actualizar/solicitud";
        let token = localStorage.getItem('token');
            let headers = {
                headers: {
                    "Accept": "application/json",
                    "HR-TOKEN-APPID": 2,
                    "HR-APP-VERSION": "1.0.0",
                    "HR-APP-TYPE": "WEB",
                    "HR-TOKEN-SESSION": token,
                    "Content-Type": "application/json"
                }
            };

            const solicitud = {
            "usuario_id": idUser,
            "cat_estatus_solicitud_id": 387,
            "convocatoria_id": Number(this.state.form.tipoMov),
            "fase1_solicitud": true,
            "fase2_evaluacion": false,
            "fase3_postulacion": false,
            "fase4_aceptado": false,
            "fase5_fin_estancia": false,
            "fase6_liberado": false,
            "observaciones": "Fase de Solicitud"
                /*"alumno_id": idAlumno,
                "cat_estatus_solicitud_id": 387,
                "convocatoria_id": Number(this.state.form.tipoMov),
                "fase1_solicitud": true,
                "fase2_evaluacion": false,
                "fase3_postulacion": false,
                "fase4_aceptado": false,
                "fase5_fin_estancia": false,
                "fase6_liberado": false,
                "observaciones": "Fase de solicitud"*/
            };
            console.log('Solicitud',solicitud);
           console.log('Formulario',this.state.form);
            axios.post(url, solicitud, headers)
                .then((res)=>{
                    console.log('entro');
                    window.Materialize.toast('Datos subidos correctamente',1500, 'green');
                    console.log("Seguimiento_Fase 1",res);
                    if (res&& res.data &&res.data.reg_ingresado ) {
                        if (this.state.form.tipoMov == 1) {
                            this.nuevaInst(res.data.reg_ingresado, this.state.form.tipoMovilidadN1, this.state.form.tipoMovilidadN2, this.state.form.tipoMovilidadN3,true);
                        }
                        else
                        {
                            this.nuevaInst(res.data.reg_ingresado, this.state.form.tipoMovilidadI1, this.state.form.tipoMovilidadI2, this.state.form.tipoMovilidadI3,true);
                        }

                    }
                })
                .catch((err)=>{
                    console.log("Error al subir datos de seguiemito_Fase 1",err)
                })
            //marco.a.ramirez.s@gmail.com {this.nuevaComv()} `${url}
    };
    handleFormConv =(e)=> {

        let {form} =this.state;
        let {value,name}=e.target;
        form[name]=value;
        //console.log('Tipo de Mov',value);
        //console.log('form-------',form);
        this.setState({form});
    };
    handleformOp =(e)=> {

        let {form} =this.state;
        let {value,name}=e.target;
        form[name]=value;
        //console.log('Universisdad',value);
        //console.log('form-------',form);
        this.setState({form});
    };

    getOpc = ()=> {
        let idUser=localStorage.getItem('userId');
        let idAlumno=localStorage.getItem('idAlumno');
        const token = localStorage.getItem('token');
        let idSolicitud = localStorage.getItem('idSolicitud');
        //console.log('Alumno',idAlumno);
        //console.log('usuario',idUser);
        const url = `http://148.204.63.213:80/IPN-Central-Route/usuario/instituto/getInstitutosBySolicitudId`;
        //console.log('%c token ', 'color: green', token);
        const headers = {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        //Peticición para listar   OPCIONES
        axios.get(`${url}/${idSolicitud}`, headers)
            .then((res) => {
                //console.log('Opciones de solicitud', 'color: blue', res.data.list_alumnos_solicitudes_institutos);
                this.setState({solicitud: res&& res.data&& res.data.list_alumnos_solicitudes_institutos[0]}, ()=>this.decidedView());
                //this.setState({catEnt: res.data.lista_catalogo_contenido}) solicitudes_institutos
            })
            .catch((err) => {
                console.log('err opciones', err)
            });
    };

    upLoadidiomaIngles=()=>{
        let token = localStorage.getItem('token');
        let idUser = localStorage.getItem('userId');
        let idAlumno=localStorage.getItem('idAlumno');
        console.log("id_Alumno",idAlumno);
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };

        //Ingles
        const DominioIngles = new FormData();
        DominioIngles.append("archivo", this.state.image['DominioIngles']);
        DominioIngles.append("filename", this.state.image['DominioIngles'].name);
        axios.post(`${url}/DominioIngles`, DominioIngles, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Archivo guardado exitosamente",1500,'green');
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error al subir el comprobante de dominio ingles, tu conexión es inestable\"", 2000);
            });

    };

    upLoadSegundoIdioma=(e)=> {
    e.preventDefault();

    let token = localStorage.getItem('token');
        let idUser = localStorage.getItem('userId');
        let idAlumno=localStorage.getItem('idAlumno');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //Segundo idioma
        const SegundoIdioma = new FormData();
        SegundoIdioma.append("archivo", this.state.image['SegundoIdioma']);
        SegundoIdioma.append("filename", this.state.image['SegundoIdioma'].name);
        axios.post(`${url}/SegundoIdioma`, SegundoIdioma, headers)
            .then(res => {
               console.log(res);
                window.Materialize.toast("Archivo guardado exitosamente",1500,'green');
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error al subir comprobante de segundo idioma , tu conexión es inestable\"", 2000);
            });
    };
    upLoadTercerIdioma=(e)=> {
        e.preventDefault();

        let token = localStorage.getItem('token');
        let idUser = localStorage.getItem('userId');
        let idAlumno=localStorage.getItem('idAlumno');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //tercer idioma
        const TercerIdioma = new FormData();
        TercerIdioma.append("archivo", this.state.image['TercerIdioma']);
        TercerIdioma.append("filename", this.state.image['TercerIdioma'].name);
        axios.post(`${url}/TercerIdioma`, TercerIdioma, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Archivo guardado exitosamente",1500,'green');
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error al subir comprobante de tercer idioma , tu conexión es inestable\"", 2000);
            });
    };
    upLoadCuartoIdioma=(e)=>{
        e.preventDefault();

        let token = localStorage.getItem('token');
        let idUser = localStorage.getItem('userId');
        let idAlumno=localStorage.getItem('idAlumno');
        const url = `http://148.204.63.213:80/IPN-Central-Route/archivo/${idAlumno}/Documentos`;
        const headers = {
            headers: {
                'HR-TOKEN-APPID': 2,
                'Accept': 'application/json',
                'HR-APP-TYPE': 'WEB',
                'HR-APP-VERSION': '1.0.0',
                'HR-TOKEN-SESSION': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        //cuarto idioma
        const CuartoIdioma = new FormData();
        CuartoIdioma.append("archivo", this.state.image['CuartoIdioma']);
        CuartoIdioma.append("filename", this.state.image['CuartoIdioma'].name);
        axios.post(`${url}/CuartoIdioma`, CuartoIdioma, headers)
            .then(res => {
                console.log(res);
                window.Materialize.toast("Archivo guardado exitosamente",1500,'green');
            })
            .catch(error => {
                console.log(error);
                console.log(error.code);
                console.log(error.message);
                window.Materialize.toast("Error al subir comprobante de cuarto idioma , tu conexión es inestable\"", 2000);
            });
    };

    checkConvo = () => {
        const url="http://148.204.63.213:80/IPN-Central-Route/usuario";
        let token=localStorage.getItem('token');
        let headers= {
            headers: {
                "Accept": "application/json",
                "HR-TOKEN-APPID": 2,
                "HR-APP-VERSION": "1.0.0",
                "HR-APP-TYPE": "WEB",
                "HR-TOKEN-SESSION": token,
                "Content-Type": "application/json",
            }
        };
        axios.get(`${url}/datos/Alumnos`,headers)
            .then((res)=>{
                //console.log('convocatoria actual ',res.data.tabla_solicitudes_seguimientos);
                this.setState({staConvo:res.data.tabla_solicitudes_seguimientos.solicitud_id},()=>this.decidedView());
                //console.log(this.state.staConvo);
             })
            .catch(err=>console.log('erro de convo',err))
    };
    handleChange = (e) => {
        let {image} = this.state;
        let file=e.target.name;
        image[file]= e.target.files[0];
        this.setState({ image })
    };
    setOptionNames(){
        if (typeof this.state.infoInt !== "undefined" && typeof this.state.infoNac !== "undefined" && typeof this.state.infoSolicitudes !== "undefined"){
            const universities = this.state.infoInt.institutos_internacionales.concat(this.state.infoNac.institutos_nacionales);
            const opc1 =  universities.find(u=>u.id === this.state.infoSolicitudes.instituto_1);
            const opc2 =  universities.find(u=>u.id === this.state.infoSolicitudes.instituto_2);
            const opc3 =  universities.find(u=>u.id === this.state.infoSolicitudes.instituto_3);
            this.setState({instituto_1:opc1&& opc1.nombre_instituto, instituto_2:opc2&& opc2.nombre_instituto, instituto_3:opc3&& opc3.nombre_instituto});
        }
    };
    decidedView(){
        if (this.state.solicitud&& this.state.solicitud.solicitud_id === this.state.staConvo)
        {
            //this.setState({solicitudes_institutos:true});
            this.setState({solicitudes_institutos:true, infoSolicitudes:this.state.solicitud}, ()=>this.setOptionNames());
            //console.log(this.state.infoSolicitudes);
        }
        else {
            this.setState({solicitudes_institutos:false});
        }
    };

componentWillMount() {

    this.sessionActive();
    this.checkInternacionales();
    this.checkNacionales();
    this.checkInfoConvoI();
    this.checkInfoConvoN();
    this.getCatalogs();
    this.checkConvo();
    this.getOpc();
    //this.nuevoSeguimiento();
    //this.nuevaInst();
}
guardar(e)
{
    e.preventDefault();
    console.log (this.state.form);
    this.nuevoSeguimiento();
    //this.nuevaInst();
    this.upLoadidiomaIngles();

};

render() {

    let {solicitudes_institutos, session, infoNac, infoInt,infoConvoN,infoConvoI, infoSolicitudes, catEnt, catNac} = this.state;
    //console.log('FROM',this.state.form);
    //console.log("InfoNac",infoNac);
    //console.log("InfoInt",infoInt);
    //console.log("InfoNac_2",infoNac&& infoNac.institutos_nacionales);
    return(
        <div>
            <NavbarStud
                logOut={this.LogOut}
            />
            {
                session === true ?
                    <>
                        <Redirect to="/login"/>
                    </>
                    : ""
            }
            {
                solicitudes_institutos === false ?
                    <>
                        <h5 className="txt-ipn"> Selecciona las Universidades Destino {/*UserName*/} </h5>

                        <div className="container pt" >
                            {
                                infoConvoN && infoConvoI &&
                                <Card className="z-depth-3">
                                    <form id={1}>
                                        <div>Convocatorias Vigentes</div>
                                        <Row>
                                            <Input
                                                id="convocatoriaNacional"
                                                l={2}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaNacional"
                                                value={infoConvoN.tabla_convocatoria.tipo_movilidad}
                                            />
                                            <Input
                                                id="convocatoriaNacionalPeriodo"
                                                l={3}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaNacionalPeriodo"
                                                value={infoConvoN.tabla_convocatoria.periodo}
                                            />
                                            <Input
                                                id="convocatoriaNacionalFecha"
                                                l={2}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaNacionalFecha"
                                                value={infoConvoN.tabla_convocatoria.fecha_publicacion}
                                            />
                                            {/*<Input
                                                id="convocatoriaNacionalBiologicas"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaNacionalBiologicas"
                                                value={infoConvoN.tabla_convocatoria.numero_apoyos_biologicas}
                                            />
                                            <Input
                                                id="convocatoriaNacionalIngenierias"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaNacionalIngenierias"
                                                value={infoConvoN.tabla_convocatoria.numero_apoyos_ingenierias}
                                            />
                                            <Input
                                                id="convocatoriaNacionalSociales"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaNacionalSociales"
                                                value={infoConvoN.tabla_convocatoria.numero_apoyos_sociales}
                                            />
                                            <Input
                                                id="convocatoriaNacionalTotales"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaNacionalTotales"
                                                value={infoConvoN.tabla_convocatoria.numero_apoyos_totales}
                                            />*/}
                                        </Row>
                                        <Row>
                                            <Input
                                                id="convocatoriaInterNacional"
                                                l={2}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaInterNacional"
                                                value={infoConvoI.tabla_convocatoria.tipo_movilidad}
                                            />
                                            <Input
                                                id="convocatoriaInterNacionalPeriodo"
                                                l={3}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaInterNacionalPeriodo"
                                                value={infoConvoI.tabla_convocatoria.periodo}
                                            />
                                            <Input
                                                id="convocatoriaInterNacionalFecha"
                                                l={2}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaInterNacionalFecha"
                                                value={infoConvoI.tabla_convocatoria.fecha_publicacion}
                                            />
                                            {/*<Input
                                                id="convocatoriaInterNacionalBiologicas"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaInterNacionalBiologicas"
                                                value={infoConvoI.tabla_convocatoria.numero_apoyos_biologicas}
                                            />
                                            <Input
                                                id="convocatoriaInterNacionalIngenierias"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaInterNacionalIngenierias"
                                                value={infoConvoI.tabla_convocatoria.numero_apoyos_ingenierias}
                                            />
                                            <Input
                                                id="convocatoriaInterNacionalSociales"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaInterNacionalSociales"
                                                value={infoConvoI.tabla_convocatoria.numero_apoyos_sociales}
                                            />
                                            <Input
                                                id="convocatoriaInterNacionalTotales"
                                                l={1}
                                                s={5}
                                                required
                                                validate
                                                type="text"
                                                name="convocatoriaInterNacionalTotales"
                                                value={infoConvoI.tabla_convocatoria.numero_apoyos_totales}
                                            />*/}
                                        </Row>
                                        <div>Selección de Idiomas</div>
                                    <br/>
                                    <Row>
                                        <Col m={3}>
                                            <select
                                                name="SegundoIdioma_Select"
                                                className="browser-default conEm"
                                                onChange={(e) => this.handleFormConv(e)}>
                                                <option id ="1" value="1">Francés</option>
                                                <option id ="2" value="2">Alemán</option>
                                                <option id ="3" value="3">Portugués</option>
                                                <option id ="4" value="4">Chino</option>
                                                <option id ="5" value="5">Otro</option>
                                            </select>
                                            <Input
                                                className="idiomas"
                                                name="nivel_2"
                                                type="text"
                                                label="Nivel"
                                            />
                                            <Input
                                                required
                                                label="agregar"
                                                type='file'
                                                name='SegundoIdioma'
                                                onChange={this.handleChange}
                                                accept='/application/pdf'
                                            />
                                            <p>Comprobante de 2do idioma</p>
                                            <br/>
                                            <Button name='idioma_s' onClick={this.upLoadSegundoIdioma}>Guardar </Button>
                                        </Col>
                                        <Col m={3}>
                                            <select
                                                name="TercerIdioma_Select"
                                                className="browser-default conEm"
                                                onChange={(e) => this.handleFormConv(e)}>
                                                <option id ="1" value="1">Francés</option>
                                                <option id ="2" value="2">Alemán</option>
                                                <option id ="3" value="3">Portugués</option>
                                                <option id ="4" value="4">Chino</option>
                                                <option id ="5" value="5">Otro</option>
                                            </select>
                                            <Input
                                                className="idiomas"
                                                name="nivel_3"
                                                type="text"
                                                label="Nivel"
                                            />
                                            <Input
                                                required
                                                label="agregar"
                                                type='file'
                                                name='TercerIdioma'
                                                onChange={this.handleChange}
                                                accept='/application/pdf'
                                            />
                                            <p>Comprobante de 3er idioma</p>
                                            <br/>
                                            <Button name='idioma_t' onClick={this.upLoadTercerIdioma }>Guardar </Button>
                                        </Col>
                                        <Col m={3}>
                                            <select
                                                name="Idioma"
                                                className="browser-default conEm"
                                                onChange={(e) => this.handleFormConv(e)}>
                                                <option id ="1" value="1">Francés</option>
                                                <option id ="2" value="2">Alemán</option>
                                                <option id ="3" value="3">Portugués</option>
                                                <option id ="4" value="4">Chino</option>
                                                <option id ="5" value="5">Otro</option>
                                            </select>
                                            <Input
                                                className="idiomas"
                                                name="nivel_4"
                                                type="text"
                                                label="Nivel"
                                            />
                                            <Input
                                                required
                                                label="agregar"
                                                type='file'
                                                name='CuartoIdioma'
                                                onChange={this.handleChange}
                                                accept='/application/pdf'
                                            />
                                            <p>Comprobante de 4to idioma</p>
                                            <br/>
                                            <Button name='idioma_c' onClick={this.upLoadCuartoIdioma} >Guardar </Button>
                                            <br/><br/>
                                        </Col>
                                    </Row>
                                        <Row>
                                            <Col>
                                                <br></br>
                                                Selecciona el tipo de convocatoria
                                            </Col>
                                            <Col>
                                                <select
                                                    name="tipoMov"
                                                    className="browser-default conEm"
                                                    onChange={(e) => this.handleFormConv(e)}>
                                                    <option id ="1" value="1">Nacional</option>
                                                    <option id ="2" value="2">Internacional</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <br></br>
                                                Opción 1.-
                                            </Col>
                                            <Col>
                                                {
                                                    this.state.form&&
                                                    this.state.form["tipoMov"]==="2"?
                                                        this.createMenuInt_2(catNac, 1)
                                                        :
                                                        this.createMenuNac_2(catEnt,1)

                                                }
                                            </Col>
                                            <Col xs={2}>
                                                {
                                                    this.state.form&&
                                                    this.state.form["tipoMov"]==="2"?
                                                        this.createMenuInt(infoInt, 1)
                                                        :
                                                        this.createMenuNac(infoNac,1)

                                                }
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <br></br>
                                                Opción 2.-
                                            </Col>
                                            <Col>
                                                {
                                                    this.state.form&&
                                                    this.state.form["tipoMov"]==="2"?
                                                        this.createMenuInt_2(catNac, 2)
                                                        :
                                                        this.createMenuNac_2(catEnt,2)

                                                }
                                            </Col>
                                            <Col xs={2}>
                                                {
                                                    this.state.form&&
                                                    this.state.form["tipoMov"]==="2"?
                                                        this.createMenuInt(infoInt,2)
                                                        :
                                                        this.createMenuNac(infoNac,2)
                                                }
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <br></br>
                                                Opción 3.-
                                            </Col>
                                            <Col>
                                                {
                                                    this.state.form&&
                                                    this.state.form["tipoMov"]==="2"?
                                                        this.createMenuInt_2(catNac, 3)
                                                        :
                                                        this.createMenuNac_2(catEnt,3)

                                                }
                                            </Col>
                                            <Col xs={2}>
                                                {
                                                    this.state.form&&
                                                    this.state.form["tipoMov"]==="2"?
                                                        this.createMenuInt(infoInt,3)
                                                        :
                                                        this.createMenuNac(infoNac,3)
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col m={5}>
                                                <br/><br/>
                                                <select
                                                    name="NivelIngles"
                                                    className="browser-default conEm"
                                                    onChange={(e) => this.handleFormConv(e)}>
                                                    <option id ="1" value="1">A2</option>
                                                    <option id ="2" value="2">B1</option>
                                                    <option id ="3" value="3">B2</option>
                                                    <option id ="4" value="4">C1</option>
                                                    <option id ="5" value="5">C2</option>
                                                </select>
                                                <Input
                                                    required
                                                    label="agregar"
                                                    type='file'
                                                    name='DominioIngles'
                                                    onChange={this.handleChange}
                                                    accept='/application/pdf'
                                                />
                                                <p>*Comprobante de dominio inglés (indispensable)</p>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Button name='Opciones' onClick={this.guardar}> {/*this.nuevaConv()*/} Guardar </Button>
                                        <br/><br/>
                                    </form>
                                </Card>
                            }
                        </div>
                    </>
                    :
                    <>
                    <h5 className="txt-ipn"> Destinos Seleccionados {/*UserName*/} </h5>
                    <div className="container pt" >
                    {
                        infoSolicitudes && infoNac&& infoInt&&
                            <Card className="z-depth-3">
                                <div>Universidades</div>
                                <Row>
                                    <Col s={2}>
                                        <Input
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcSeleccionadas"
                                            value={infoSolicitudes.solicitud_id}
                                            label={"No. de Solicitud"}

                                        />
                                    </Col>
                                    <Col s={10}>
                                        <Input
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcSeleccion"
                                            value={infoSolicitudes.no_opcion}
                                            label={"Escuela Aprobada"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <Input
                                            s={12}
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcUno"
                                            value={this.state.instituto_1}
                                            placeholder={"Opción numero 1"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <Input
                                            s={12}
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcDos"
                                            value={this.state.instituto_2}
                                            placeholder={"Opción numero 2"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12} >
                                        <Input
                                            s={12}
                                            id="opcSeleccionadas"
                                            required
                                            validate
                                            type="text"
                                            name="opcTres"
                                            value={this.state.instituto_3}
                                            placeholder={"Opción numero 3"}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                    }
                    </div>
            </>
            }

        </div>

    );
  }
}

export default UpdateProfileStud
import React from 'react';
import {Navbar,NavItem} from 'react-materialize'
import '../styles/home.css'
import {Link} from "react-router-dom";


const NavbarGeneral= () => {
  return(
    <Navbar   className="col-ipn" left>
      <NavItem className="  pt-2"  >
          <Link to="/">
              {/*<img src="./images/CCA.png" width="50" alt=""/>*/}
          </Link>
      </NavItem>
      <NavItem className="  pt-2"  >
        <h5 className='sub'>Coordinación de Cooperación Académica <br/>
        <span>Instituto Politénico Nacional</span>
        </h5>
    </NavItem>
    </Navbar>
  )
}
export default NavbarGeneral;

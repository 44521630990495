import  React from 'react'
import {Link} from 'react-router-dom'
import {Icon } from 'react-materialize'

const  Error404 =()=> {

  return(
    <div>
      <h1> No deberias estar aquí </h1>
      <h2>Regresar al inicio <Link to={"/"}>
        <Icon
          medium
        >
          home
        </Icon>
      </Link>
      </h2>

      <img src="../../images/burrito.png"  className="responsive-img" width="400"   alt=""/>
      <h2>Regresar al inicio</h2>
    </div>

  )
}

export default Error404
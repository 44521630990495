import React, {Component} from 'react';
import NavbarGeneral from "./NavbarGeneral";
import axios from 'axios';
import {Col, Row} from 'react-materialize'
import '../styles/login.css'
import { sha256 } from 'js-sha256';
import FormLogin from "./FormLogin";
import Loader from 'react-loader-spinner'

class Login extends Component{

  state={
    tipoPerfil:null,loading:false,
    form:{
      username:"",
      password:""
    }
  };

  componentWillMount() {

    // localStorage.removeItem("infoUserSto");
    // localStorage.removeItem("tokenSto");
  }

  requestRegister = (e) => {
    e.preventDefault();
    let {form}= this.state;
    this.setState({loading:true});

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/login";
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "Content-Type": "application/json",
      }
    };
    //console.log(head);
    let data = {
      nickname: form['nickname'],
      secretword: sha256(form['secretword'])
    };
    //console.log(data);
    axios.post(url, JSON.stringify(data), head)
    .then(response => {
      console.log("response de token",response);
      const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio";

      const newHead = {
        headers: {
          "Accept": "application/json",
          "HR-TOKEN-APPID": 2,
          "HR-APP-VERSION": "1.0.0",
          "HR-APP-TYPE": "WEB",
          "HR-TOKEN-SESSION": response.headers['hr-token-session'],
          "Content-Type": "application/json",
        }
      };
        axios.get(newUrl, newHead)
        .then(res => {
          let userName=`${res.data.nombres} ${res.data.ap_paterno} ${res.data.ap_materno}`;

          localStorage.setItem('profile', res.data.nombre_perfil);
          localStorage.setItem('userName', userName);

          localStorage.setItem('userId', res.data.usuario_id);
          localStorage.setItem('token', response.headers['hr-token-session']);

          console.log("Res.data",res.data);
          this.setState({ loading: false,tipoPerfil:res.data.nombre_perfil });
          this.checkPerfil()
        })
      })

      .catch(error => {
        this.setState({
          loading: false,
          open: true
        });

        if (error.message === 'Request failed with status code 409') {
            window.Materialize.toast('Sesion ya iniciada', 1500)

        } else
        if (error.message === 'Request failed with status code 400') {
            window.Materialize.toast('Usuario no registrado', 1500)
        }
      })

  };

  checkPerfil=()=>{
    let {tipoPerfil}=this.state;
    console.log("entro a check Perfil",tipoPerfil);
    this.setState({loading:false});

    if(tipoPerfil==="ALUMNOS"){
    this.props.history.push("/homeAlum")
    //this.props.history.push("/sinAcceso")
    }
    if(tipoPerfil==="UA"){
    this.props.history.push("/homeUA")
    }
    if(tipoPerfil==="CCA"){
      this.props.history.push("/homeCCA")
    }

  };

  handleChange= (e) => {
    let {form} =this.state;
    let field=e.target.name;
    form[field]= e.target.value;

    this.setState({ form});
  };

  handleInviCode = (e) =>{
    let {codeGuest}= this.state;
    let name=e.target.name;
    codeGuest[name]=e.target.value;
    console.log(codeGuest);
    this.setState({codeGuest})
  };

  render() {
    let {loading} = this.state;
    return (
      <>
        <NavbarGeneral/>
        {
          loading?
            <Loader
              type="Rings"
              color="#4b0024"
              height="400"
              width="400"
            />
          :
          <>
            <Row className="pt">
              <Col l={2}  className=" "  >
              </Col>
              <Col l={4} m={12} className=" "  >
                <img src="../../images/CCA_big.png" alt=""/>
              </Col>
              <Col l={4} m={12} className=" "  >
                <FormLogin
                  className="z-depth-5"
                  handleChange={this.handleChange}
                  reqguest={this.requestRegister}
                />
              </Col>
              <Col l={2}   className=" "  >
              </Col>
            </Row>
          </>
        }

      </>
    );
  }
}

export default Login;

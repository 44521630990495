import React from 'react'
import {Button, Card, Table} from 'react-materialize'
import {Link} from "react-router-dom";

const TableContacts= ({data}) =>{
  return(
    <Card>
      <strong>Contacto de Emegencia </strong>
        <br/>
      <Table  responsive={true} className="white">
        <tbody>
        <tr>
          <td><strong> Nombre</strong></td>
          <td> {`${data.contacto_nombres}  ${data.contacto_ap_paterno}  ${data.contacto_ap_materno}`} </td>
          <td><strong>Dirección </strong></td>
          <td>
            {
              `Calle ${data.contacto_calle}, No. ${data.contacto_no_exterior}, Colonia ${data.contacto_colonia},
              Municipio ${data.contacto_municipio_delegacion}, CP ${data.contacto_codigo_postal}`} </td>
        </tr>
        <tr>
          <td><strong> Email</strong></td>
          <td>{`${data.contacto_email}  `}</td>
          <td> <strong>Teléfonos</strong></td>
          <td>{`${data.contacto_tel_celular}  `} {data.contacto_tel_particular?`${data.contacto_tel_particular}`:""}</td>
        </tr>
        </tbody>
      </Table>
        <br/>
      <Link  to="/homeAlum">
        <Button
            name="modificar"
        >        regresar
        </Button>
      </Link>
    </Card>
  );
};

export default TableContacts;
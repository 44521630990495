import React, {Component} from 'react'
import NavbarStud from "./NavbarStud";
import axios from "axios";
import {Row,Col,ProgressBar,Icon,Button} from 'react-materialize'
import '../styles/homeAl.css'

import {Link, Redirect} from "react-router-dom";

class RegisterStud extends Component{

  state={
    url :"http://148.204.63.213:80/IPN-Central-Route/usuario/datos" ,
    token : localStorage.getItem('token'),session:false,
    headers:{
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "Content-Type": "application/json",
      }
    }, countRegister:[],staConvo:null,tipoConvo:{}
  }

  LogOut = () => {

    let token=localStorage.getItem('token')
    console.log('entró al logout',token)

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout"
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    }

    axios.get(url, head)
    .then(res => {
      console.log('res de logout', res)


      localStorage.removeItem('profile');
      localStorage.removeItem('userName');

      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      localStorage.removeItem('alumno_id');

      this.props.history.push("/login");
    })

    .catch(err=>console.log(err))
  }

  checkProfileStudent=()=>{

    let {token, headers,url,countRegister}= this.state;
    headers['headers']['HR-TOKEN-SESSION']=token;

    // checa si en la BD existen conEMERGENCIA
    axios.get(`${url}/ContactoEmergencia`,headers)
      .then((res)=>{
        this.setState({staContEme:res.data.tabla_contactos_emergencias,countRegister:countRegister.push("eme")})
        console.log('emer',countRegister)
      })
      .catch((err)=>{
        console.log('---error contacto emer',err.response)

      })

    // checa si en la BD existen datMEDICOS
    axios.get(`${url}/Medicos`,headers)
      .then((res)=>{
        this.setState({staMedi:res.data.tabla_medicos,countRegister:countRegister.push("med")})
        console.log('medi',countRegister)
      })
      .catch((err)=>{
        console.log('---error contacto emer',err.response)
      })

    // checa si en la BD existen los datos SOCIOECONOMICOS
    axios.get(`${url}/SocioEconomicos`,headers)
      .then((res)=>{
        // console.log('%c Res socio****** ', 'color: green',res.data.tabla_socio_economicos)
        this.setState({staSocio:res.data.tabla_socio_economicos,countRegister:countRegister.push("socio")})
        console.log('socio',countRegister)
      })
      .catch((err)=>{
        console.log('error',err.response)
      })

    // checa si en la BD existen los datos DOMCILIO
    axios.get(`${url}/Domicilio`,headers)
      .then((res)=>{
        this.setState({staDomi:res.data.tabla_domicilios,countRegister:countRegister.push("domi")})
        console.log('domici',countRegister)
      })
      .catch((err)=>{
        console.log('error',err.response)
      })

  }

  checkMovilidad=()=>{
    let {token, headers}= this.state;
    headers['headers']['HR-TOKEN-SESSION']=token;
    const url="http://148.204.63.213:80/IPN-Central-Route/usuario"

    axios.get(`${url}/datos/Alumnos`,headers)
      .then((res)=>{
        console.log('%c res convo',"color:orange",res.data.tabla_solicitudes_seguimientos.convocatoria_id)

        this.setState({staConvo:res.data.tabla_solicitudes_seguimientos.convocatoria_id})

        axios.get(`${url}/convocatoria/${this.state.staConvo}`,headers)
          .then((res)=>{
            console.log('%c info deta convo ', 'color: green',res.data.tabla_convocatoria);
            this.setState({tipoConvo:res.data.tabla_convocatoria})
          })
          .catch(err=> console.log('%c error deta convo', 'color: yellow','font-size:30px',err))

      })
      .catch(err=>console.log('erro de convo',err))
  }

  sessionActive=()=>{
    let {token}= this.state
    const newUrl = "http://148.204.63.213:80/IPN-Central-Route/usuario/inicio"

    const newHead = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    }

    axios.get(newUrl, newHead)
      .then(res => {
        console.log("Token activo")
      })
      .catch((err)=>{
        console.log("la sesion acabo")
        this.setState({session:true})
      })
  }


  componentDidMount() {
    this.checkProfileStudent();
    this.sessionActive();
    this.checkMovilidad();
  }

  render() {
    let {countRegister,tipoConvo,session} =this.state
    return(
      <>
        <NavbarStud
          logOut={this.LogOut}
        />
        {
          session===true?
          <>
            <Redirect to="/login"/>
          </>
          :""
        }

        <Row className="container txt-ipn">
          <h4 className="txt-ipn">Realizar Registro</h4>
          <Col m={6} className="estado" >
            <p><strong>Estado:</strong>  {
              countRegister===4
                ?"registro completo"
                :"falta completar el registro" +
                ""
              }
            </p>
            <p><strong>Movilidad:</strong> { tipoConvo['tipo_movilidad'] }</p>
            <p><strong>Estatus de la movilidad: </strong></p>
          </Col>
          <Col m={6}>

          </Col>
        </Row>

        <Row className="container">
          <div className="fath">
            <Icon>
              looks_one
            </Icon>
            <ProgressBar progress={
              countRegister!==4
                ?50
                :100
            }/>
            <Icon>
              looks_two
            </Icon>
            <ProgressBar progress={50}/>
            <Icon>
              looks_3
            </Icon>
            <ProgressBar progress={0}/>
            <Icon>
              looks_4
            </Icon>
            <ProgressBar progress={0}/>
            <Icon>
              looks_5
            </Icon>

          </div>
         </Row>
        <Row className="container">
          <Col m={6}>
            <Link to="/actualizaDatosAlum">
              <Button
                floating
                large
                className="btn-ipn"
                waves="light"
                icon="person"
              />
            </Link>
            <p>Actualizar datos</p>
          </Col>
          <Col m={6}>
            <Link to="/cambiarFotoAlum">
            <Button
              floating
              large
              className="btn-ipn"
              waves="red"
              icon="add_a_photo"
            />
            </Link>
            <p>Cambiar foto</p>
          </Col>
        </Row>
        <Row className="container">
          <Col m={6}>
            <Link to="/agregarDocAlum">
            <Button
              floating
              large
              className="btn-ipn"
              waves="red"
              icon="cloud_upload"

            />
            </Link>
            <p>Subir documentos   </p>
          </Col>
          <Col m={6}>

          </Col>
        </Row>
      </>
    )
  }

}

export default RegisterStud
import React from 'react'
import {Navbar, NavItem,Icon} from 'react-materialize'
import '../styles/navbar.css'

const NavbarStud= (props) => {
  return(
    <Navbar   className="col-ipn " >
      <div  className=" navbarF">
        {/*{console.log('props',props)}*/}
      <NavItem  href="/homeAlum" >
        Inicio
        <Icon left>
          home
        </Icon>
      </NavItem>
      {/*<NavItem   href="/registroAlum" >
        Registro
        <Icon left>
          group
        </Icon>
      </NavItem>*/}
      <NavItem    onClick={props.logOut} >
        Cerrar Sesión
        <Icon left>
          exit_to_app
        </Icon>
      </NavItem>
      </div>
    </Navbar>
  )
}

export default NavbarStud
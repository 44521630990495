import React from 'react';
import {Link }from 'react-router-dom'
import {Button,Row,Col,Card,Input} from 'react-materialize';
import '../styles/login.css'
const FormLogin = ({reqguest,handleChange}) => (

  <Row>

      <Card className="white">
        <div className=" blue-text center-align">
          <img src='./images/user.png' width="95" alt=""/>
        </div>
        <form onSubmit={reqguest} >
          <Input
            s={12}
            id="icon_user"
            type="text"
            name="nickname"
            onChange={handleChange}
            required
            aria-required="true"
            label="e-mail"
            icon="mail_outline"
            className="validate" validate>
          </Input>
          <Input
            s={12}
            suggested="current-password"
            name="secretword"
            id="icon_prefix"
            type="password"
            onChange={handleChange}
            required
            label="contraseña"
            icon="lock_outline"
            aria-required="true"
            className="validate" >
          </Input>
          <Button waves='light' className="btn-ipn " >Acceder</Button><br/><br/>

          <Row>
            <Col l={4}>
              <p> </p>
            </Col>

            <Col l={4}>
              ¿NO TIENES CUENTA?
              <Link to="/preRegister">
                <p> PREREGISTRO</p>
              </Link>
            </Col>

            <Col l={4}>
              <p> </p>
            </Col>

          </Row>
        </form>
      </Card>

  </Row>
);

export default FormLogin;
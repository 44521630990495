import React from 'react'
import {Navbar, NavItem,Icon} from 'react-materialize'
import '../styles/navbar.css'

const NavbarCCA= (props) => {
  return(
    <Navbar   className="col-ipn " >
      <div  className=" navbarF">
        <NavItem  href="/homeCCA" >
          Inicio
          <Icon left>
            home
          </Icon>
        </NavItem>
        <NavItem  href="/asignacionCCA">
          Asignación
          <Icon left>
            group
          </Icon>
        </NavItem>
        <NavItem    onClick={props.logOut} >
          Cerrar Sesión
          <Icon left>
            exit_to_app
          </Icon>
        </NavItem>
      </div>
    </Navbar>
  )
};

export default NavbarCCA
import React,{Component, Fragment} from 'react'
import {Input, Row, Button, Col} from 'react-materialize'
import M from "materialize-css";
import NavbarStud from "./NavbarStud";
import axios from "axios";
import TableContacts from "./TableContaStud";
import TableAddreStud from "./TableAddreStud";
import TableMedStud from "./TableMedStud";
import {Link} from "react-router-dom";
//import "materialize-css/dist/css/materialize.min.css";

class UpdateProfileStud extends Component{
  state={
    dataDomi:null,Loading:true,
    form:{
      contacto_calle: '',
      contacto_colonia: '',
      contacto_no_exterior: '',
      contacto_no_interior: '',
      contacto_municipio_delegacion: '',
      contacto_codigo_postal: ''

    },convo:{},
    value:"",staContEme:null,staSocio:null,staDomi:null,staMedi:null,staConvo:null,infoConvo:{},
    catStAl:null,catNivEs:null,catSemes:null,catEstCiv:null,
    catEnt:null,catNac:null,catParent:null,catSegSoci:null,catConvoca:null
  };

  getCatalogs = ()=> {
    const token=localStorage.getItem('token');
    const url = `http://148.204.63.213:80/IPN-Central-Route/catalogos/`;
    //console.log('%c token ', 'color: green',token);
    const headers = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    //Checar convovatorias habilitadas
    const url2="http://148.204.63.213:80/IPN-Central-Route/usuario/convocatoria/habilitadas";
    axios.get(url2,headers)
      .then((res)=>{
       // console.log('convocatorias hab',res.data.convocatorias_vigentes);
        this.setState({catConvoca:res.data.convocatorias_vigentes})
      })
      .catch(err=>console.log(err));

    //Listar  TODOS los catalogos
    axios.get(`${url}`,headers)
      .then((res)=>{
         //console.log('%c lista de catalogos ----------','color:purple', res.data)
      })
      .catch((err)=>{
        console.log(err)
      });

    //Peticición para listar el ESTATUS del alumno 1
    axios.get(`${url}`,headers)
      .then((res)=>{
        // console.log('catalogos',res.data)
        this.setState({catStAl:res.data.lista_catalogo_contenido})

      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });
    //Peticición para listar ESTADO CIVIL
    axios.get(`${url}/6`,headers)
      .then((res)=>{
        // console.log('catalogos',res.data)
        this.setState({catEstCiv:res.data.lista_catalogo_contenido})
      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });

    //Peticición para listar el nivel de estudios del alumno 8
    axios.get(`${url}/8`,headers)
      .then((res)=>{
        // console.log('catalogos 8',res.data.lista_catalogo_contenido)
        this.setState({catNivEs:res.data.lista_catalogo_contenido})
      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });
    //Peticición para listar el SEMESTRE del alumno 2
    axios.get(`${url}/2`,headers)
      .then((res)=>{
        // console.log('catalogos 2',res.data.lista_catalogo_contenido)
        this.setState({catSemes:res.data.lista_catalogo_contenido})
      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });

    //Peticición para listar   ESTADOS 4
    axios.get(`${url}/4`,headers)
      .then((res)=>{
        //console.log('%c catalogos 4 ESTADOS','color: pink', res.data.lista_catalogo_contenido);
        this.setState({catEnt:res.data.lista_catalogo_contenido})
      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });
    //Peticición para listar NACIONES 3
    axios.get(`${url}/3`,headers)
      .then((res)=>{
        //console.log('%c catalogos 3 naciones','color: yellow', res.data.lista_catalogo_contenido);
        this.setState({catNac:res.data.lista_catalogo_contenido})
      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });

    //Peticición para listar los PARENTESCO 7
    axios.get(`${url}/7`,headers)
      .then((res)=>{
        // console.log('catalogos parent',res.data.lista_catalogo_contenido)
        this.setState({catParent:res.data.lista_catalogo_contenido})
      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });
    //Peticición para listar  tipos SEGUROS 5
    axios.get(`${url}/5`,headers)
      .then((res)=>{
        // console.log('catalogos parent',res.data.lista_catalogo_contenido)
        this.setState({catSegSoci:res.data.lista_catalogo_contenido})
      })
      .catch((err)=>{
        console.log('err catalogos',err)
      });

  };

  LogOut = () => {

    let token=localStorage.getItem('token');
    console.log('entró al logout',token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/acceso/logout";
    const head = {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };

    axios.get(url, head)
      .then(res => {
        // console.log('res de logout', res)


        localStorage.removeItem('profile');
        localStorage.removeItem('userName');

        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('alumno_id');

        this.props.history.push("/login");
      })

      .catch(err=>console.log(err))

  };

  checkForms = () => {
    const url ="http://148.204.63.213:80/IPN-Central-Route/usuario/datos";
    let token=localStorage.getItem('token');
    let headers= {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
    // checa si en la BD existen los datos DOMCILIO

    axios.get(`${url}/Domicilio`,headers)
        .then((res)=>{
          this.setState({staDomi:res.data.tabla_domicilios});
          //console.log('Domicilio',res);
        })
        .catch((err)=>{
          console.log('error',err.response);
          console.log('erro codigo',err.response.data.code);
          if (err.response.data.code===400){
            this.setState({staDomi:false})
          }
        });
    // checa si en la BD existen los datos de Contacto de Emergencia
    axios.get(`${url}/ContactoEmergencia`,headers)
      .then((res)=>{
        this.setState({staContEme:res.data.tabla_contactos_emergencias});
        // console.log('%c Res emergencia****** ', 'color: yellow',res.data.tabla_contactos_emergencias)
      })
      .catch((err)=>{
        console.log('---error contacto emer',err.response)

      });
    // checa si en la BD existen los datos MEDICOS

    axios.get(`${url}/Medicos`,headers)
      .then((res)=>{
        this.setState({staMedi:res.data.tabla_medicos});
        //console.log('%c Res MEDI ', 'color: green',res.data.tabla_medicos);
      })
      .catch((err)=>{
        //console.log('error',err.response);
        //console.log('erro codigo',err.response.data.code);
        if (err.response.data.code===400){
          this.setState({staMedi:false})
        }
      });

  };

  checkConvo = () => {
    const url="http://148.204.63.213:80/IPN-Central-Route/usuario";
    let token=localStorage.getItem('token');
    let headers= {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
    axios.get(`${url}/datos/Alumnos`,headers)
    .then((res)=>{
      //console.log('convocatoria actual ',res.data.tabla_solicitudes_seguimientos);

      this.setState({staConvo:res.data.tabla_solicitudes_seguimientos.solicitud_id});

      axios.get(`${url}/convocatoria/1`,headers)
        .then((res)=>{
          //console.log('%c info deta convo ', 'color: green',res.data.tabla_convocatoria);
          this.setState({infoConvo:res.data.tabla_convocatoria,Loading:false})
        })
        .catch(err=> console.log('%c error data convo', 'color: yellow','font-size:30px',err))

    })
    .catch(err=>console.log('erro de convo',err))
  };

  handleformMedic =(e)=> {

    let {form} =this.state;
    let {value,name}=e.target;
      form[name]=value;
    console.log('form-------',form);

    this.setState({form});
  };

  handleformEmer =(e)=> {

    let {form} =this.state;
    let {value,name}=e.target;

    if(name=== 'cat_entidad_federativa_id'||name=== 'cat_nacion_id'||name=== 'cat_parentesco_id'){
      //console.log('entro');
      form[name]=parseInt(value);
      //console.log('form[name]',form[name])
    }else{
      form[name]=value;
    }
    //console.log('form-------',form);
    this.setState({form});
  };

  handleFormDomi =(e)=> {

    let {form} =this.state;
    let {value,name}=e.target;

    form[name]=value;
    //console.log('form-------',form);
    this.setState({form});
  };

  updateDatos =()=>{

    const url="http://148.204.63.213:80/IPN-Central-Route/usuario/datos/actualizar/Domicilio";
    let token=localStorage.getItem('token');
    let headers= {
      headers: {
        "Accept": "application/json",
        "HR-TOKEN-APPID": 2,
        "HR-APP-VERSION": "1.0.0",
        "HR-APP-TYPE": "WEB",
        "HR-TOKEN-SESSION": token,
        "Content-Type": "application/json",
      }
    };
    let Datos = {
      "calle":"Herrera"
    };

    axios.put(url, Datos, headers)
        .then((res)=>{
          console.log('Reg Actualizado',res);
        })
        .catch(err=>console.log('error al actulaizar',err))
  };


  submitFormMedic= (e)=>{
    e.preventDefault();
    const {form}= this.state;
    let  nameForm=e.target.name;
    //console.log('e--------->',e.target.name);
    //console.log("datos postear",form );

    let token=localStorage.getItem('token');
    //console.log("token",token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/ingresar/Medicos";
    const headers = {
      headers: {
        'HR-TOKEN-APPID': 2,
        'Accept': 'application/json',
        'HR-APP-TYPE': 'WEB',
        'HR-APP-VERSION': '1.0.0',
        'HR-TOKEN-SESSION': token,
        'Content-Type': 'application/json'
      }
    };

    delete form.contacto_calle;
    delete form.contacto_codigo_postal;
    delete form.contacto_colonia;
    delete form.contacto_municipio_delegacion;
    delete form.contacto_no_exterior;
    delete form.contacto_no_interior;
    form.alergico_medicamento_alguna_alergia = "Ninguna";
    //console.log("Respuesta",form);

    axios.post(url, form, headers)
      .then((res)=>{
        //console.log("entro v");
        //console.log("res",res);
        window.Materialize.toast("Tus datos médicos se han guardado exitosamente",1500, 'green');
        this.setState({form:{}});
        this.disableForm(nameForm);
        this.checkForms()

      })

      .catch((err)=>{
        //console.log("error de datos",err)
      })

  };

  submitFormEmer= (e)=>{
    e.preventDefault();
    const {form}= this.state;

    let token=localStorage.getItem('token');
    //console.log("token",token);

    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/ingresar/ContactoEmergencia";
    const headers = {
      headers: {
        'HR-TOKEN-APPID': 2,
        'Accept': 'application/json',
        'HR-APP-TYPE': 'WEB',
        'HR-APP-VERSION': '1.0.0',
        'HR-TOKEN-SESSION': token,
        'Content-Type': 'application/json'
      }
    };
console.log('Form',form);
    axios.post(url, form, headers)
      .then((res)=>{
        //console.log("res",res)
        window.Materialize.toast("Tus contactos de emergencia han sido subidos exitosamente",1500, 'green');
        this.setState({form:{}});
        this.checkForms()
      })

      .catch((err)=>{
        //console.log("error de datos",err)
      })

  };

  submitFormDomi= (e)=>{
    // Submit para domicilio
      e.preventDefault();
    let {form}= this.state;
    let token=localStorage.getItem('token');

    delete form.contacto_calle;
    delete form.contacto_codigo_postal;
    delete form.contacto_colonia;
    delete form.contacto_municipio_delegacion;
    delete form.contacto_no_exterior;
    delete form.contacto_no_interior;
    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/ingresar/Domicilio";
    const headers = {
      headers: {
        'HR-TOKEN-APPID': 2,
        'Accept': 'application/json',
        'HR-APP-TYPE': 'WEB',
        'HR-APP-VERSION': '1.0.0',
        'HR-TOKEN-SESSION': token,
        'Content-Type': 'application/json'
      }
    };

    axios.post(url, form, headers)
      .then((res)=>{
        window.Materialize.toast('Datos de domicilio subidos correctamente',1500,'green');
        this.setState({form:{}});
        this.checkForms();
        //console.log("res",res)
      })

      .catch((err)=>{
        console.log("error de datos",err)
      })

  };

  checkDataDomi=()=> {

    const token=localStorage.getItem('token');
    const headers = {
      headers: {
        'HR-TOKEN-APPID': 2,
        'Accept': 'application/json',
        'HR-APP-TYPE': 'WEB',
        'HR-APP-VERSION': '1.0.0',
        'HR-TOKEN-SESSION': token,
        'Content-Type': 'application/json'
      }
    };
    const url = "http://148.204.63.213:80/IPN-Central-Route/usuario/datos/Domicilio";

    axios.get(url,  headers)
      .then((res)=>{
        // console.log('res.domicilio************',res.data)
        this.setState({dataDomi:true});
        //console.log("Datos Domi",this.state.dataDomi);
      })
      .catch((err)=>{
        console.log('err domiclio',err);
        this.setState({dataDomi:false})
      })
  };

  disableForm=(nameForm)=>{
  let contacto=document.getElementsByClassName(`${nameForm}`);
  //console.log('medicos lenght', nameForm);

    for (let i=0;i<contacto.length;i++){
      //console.log("entro");
      contacto[i].disabled=true
    }
  };

  copyAddress=(e)=>{
    let {form} =this.state;
    let {value}=e.target;
    //console.log("entro",form.calle);
    //console.log("Estado-Domicilio",this.state.staDomi);
    if (value){
      form.contacto_calle=this.state.staDomi.calle;
      form.contacto_colonia=this.state.staDomi.colonia;
      form.contacto_no_exterior=this.state.staDomi.no_exterior;
      form.contacto_no_interior=this.state.staDomi.no_interior;
      form.contacto_municipio_delegacion=this.state.staDomi.municipio_delegacion;
      form.contacto_codigo_postal=this.state.staDomi.codigo_postal;
      form.cat_nacion_id=this.state.staDomi.cat_nacion_id;
      form.cat_entidad_federativa_id=this.state.staDomi.cat_entidad_federativa_id;
      window.Materialize.toast('Datos copiados correctamente',1500,'green');
    }
    this.setState(form)
  };
  componentDidMount() {
    //console.log("instance",instance);
    window.scrollTo(0, 0);
    //console.log("token",token);
    M.Tabs.init(this.Tabs);
    this.checkDataDomi();
    this.checkForms();
    this.checkConvo();
    //M.AutoInit();
    //this.updateDatos();
  }
  componentWillMount() {
    this.getCatalogs();
    //this.checkConvo();

    //this.setState({form:{}});
    //this.checkForms()
  }

  render() {

    let {staDomi,staMedi,staContEme,catSegSoci,catNac,catEnt,catParent} = this.state;

    return(
      <>
        <NavbarStud
          logOut={this.LogOut}
        />
        {/*<button onClick={this.checkRequest}>Prueba </button>*/}

        <div className="container pt">
          <div className="col s12">
            <ul
                ref={Tabs => {
                  this.Tabs = Tabs;
                }}
                id="tabs-swipe-demo"
                className="tabs"
            >

              <li className="tab col s3 "><a href="#domicilio">Datos Personales (Domicilio) </a></li>
              <li className="tab col s3"><a href="#emergencia">Contactos de emergencia</a></li>
              <li className="tab col s3"><a href="#medicos">Datos Médicos</a></li>

            </ul>
          </div>

          <div id="domicilio" className="col s12">
             {
               staDomi ?
                 <>
                   <TableAddreStud
                     data={staDomi}
                   />
                 </>
                 :
                 <>
                   <form onSubmit={this.submitFormDomi}>
                     <Row>
                       <Input
                         s={12} m={3}
                         required
                         validate
                         className="domicilio"
                         name="calle"
                         type="text"
                         onChange={this.handleFormDomi}
                         label="*Calle"
                       />
                       <Input
                         s={12} m={3}
                         required
                         validate
                         className="domicilio"
                         name="no_exterior"
                         type="text"
                         onChange={this.handleFormDomi}
                         label="*No. Exterior"
                       />
                       <Input
                         s={12} m={3}
                         className="domicilio"
                         name="no_interior"
                         type="text"
                         onChange={this.handleFormDomi}
                         label="No. Interior"
                       />
                       <Input
                         s={12} m={3}
                         required
                         validate
                         className="domicilio"
                         name="colonia"
                         type="text"
                         onChange={this.handleFormDomi}
                         label="*Colonia"
                       />
                     </Row>
                     <Row className=" pt">
                       <Input
                         s={12} m={3}
                         required
                         validate
                         className="domicilio"
                         name="municipio_delegacion"
                         type="text"
                         onChange={this.handleFormDomi}
                         label="*Municipio-Delegación"
                       />
                       <Input
                         s={12} m={3}
                         required
                         validate
                         className="domicilio"
                         name="codigo_postal"
                         type="number"
                         onChange={this.handleFormDomi}
                         label="*Código Postal"
                       />
                       <Col s={12} m={3}>
                         <select  name="cat_nacion_id"  className="browser-default domicilio" onChange={(e)=>this.handleFormDomi(e)}>
                           <option defaultValue={0} > País</option>
                           {
                             catNac
                                 ?
                                 catNac

                                     .map((el,i)=>{
                                       return(
                                           <option key={i}   value={el.objeto_id}  > {el.descripcion_objeto} </option>
                                       )
                                     })
                                 : <option defaultValue={0} > Estatus</option>
                           }
                         </select>
                       </Col>
                       <Col s={12} m={3}>
                         {
                           this.state.form&&
                           Number(this.state.form['cat_nacion_id']) === 186 ?
                               <select name="cat_entidad_federativa_id" className="browser-default domicilio"
                                       onChange={(e) => this.handleFormDomi(e)}>
                                 <option defaultValue={0}> Entidad Federativa</option>
                                 {
                                   catEnt
                                       ?
                                       catEnt
                                           .map((el, i) => {
                                             return (
                                                 <option key={i} value={el.objeto_id}> {el.descripcion_objeto} </option>
                                             )
                                           })
                                       : <option defaultValue={0}> Estatus</option>
                                 }
                               </select>
                               :
                                <span>

                                </span>
                           }
                       </Col>
                     </Row>
                     <div>
                       <Link  to="/homeAlum">
                       <Button>
                         Regresar al Inicio
                       </Button>
                       </Link>
                       &nbsp; &nbsp; &nbsp;
                       <Button
                         name="domicilio"
                       >
                         Guardar y Continuar
                       </Button>
                     </div>
                   </form>
                 </>
             }
           </div>
          <div id="emergencia" className="col s12">
            {
              staContEme
                  ?
                  <>
                    <TableContacts
                        data={staContEme}
                    />
                  </>
                  :
                  <>
                    <form onSubmit={this.submitFormEmer}>
                      < Row className = " pt" >
                        < Input
                            required
                            validate
                            id="name"
                            className="conEm "
                            s={12} m={3}
                            name="contacto_nombres"
                            type="text"
                            onChange={this.handleformEmer}
                            label="*Nombre (s)"
                        />
                        <Input
                            validate
                            required
                            className="conEm"
                            s={12} m={3}
                            name="contacto_ap_paterno"
                            type="text"
                            onChange={this.handleformEmer}
                            label="*Apellido Paterno"
                        />
                        <Input
                            className="conEm"
                            s={12} m={3}
                            name="contacto_ap_materno"
                            type="text"
                            onChange={this.handleformEmer}
                            label="*Apellido  Materno"
                        />
                        <Col s={12} m={3}>
                          <select
                              name="cat_parentesco_id"
                              className="browser-default conEm"
                              onChange={(e) => this.handleformEmer(e)}>
                            <option defaultValue={0}> Parentesco</option>
                            {
                              catParent
                                  ?
                                  catParent

                                      .map((el, i) => {
                                        return (
                                            <option key={i} value={el.objeto_id}> {el.nombre_objeto} </option>
                                        )
                                      })
                                  : <option defaultValue={0}> Estatus</option>
                            }
                          </select>
                        </Col>
                      </Row>
                      <Row >
                        <Input
                            s={12} m={3}
                            className="conEm"
                            name="contacto_email"
                            type="email"
                            onChange={this.handleformEmer}
                            label="E-mail"
                        />
                        <Input
                            s={12} m={3}
                            required
                            validate
                            className="conEm"
                            name="contacto_tel_celular"
                            type="number"
                            onChange={this.handleformEmer}
                            label="Celular"
                        />
                        <Input
                            s={12} m={3}
                            className="conEm"
                            name="contacto_tel_particular"
                            type="number"
                            onChange={this.handleformEmer}
                            label="Tel. Particular"
                        />
                        {/*-------Copia dirección-------*/}
                        <Input
                              type="checkbox"
                              className="conEm"
                              name="contacto_copy_dir"
                              label="Copiar dirección anterior"
                              class="filled-in"
                              onChange={this.copyAddress}

                        />
                      </Row>

                            <Fragment>
                              <Row className=" pt">
                                <Input
                                    s={12} m={3}
                                    validate
                                    className="conEm"
                                    name="contacto_calle"
                                    type="text"
                                    onChange={this.handleformEmer}
                                    placeholder="*Calle"
                                    InputLabelProps={{shrink:true}}
                                    value={this.state.form.contacto_calle}
                                />
                                <Input
                                    s={12} m={3}
                                    validate
                                    className="conEm"
                                    name="contacto_no_exterior"
                                    type="text"
                                    onChange={this.handleformEmer}
                                    placeholder="No. Exterior"
                                    value={this.state.form.contacto_no_exterior}
                                />
                                <Input
                                    s={12} m={3}
                                    className="conEm"
                                    name="contacto_no_interior"
                                    type="text"
                                    onChange={this.handleformEmer}
                                    placeholder="No. Interior"
                                    value={this.state.form.contacto_no_interior}
                                />
                                <Input
                                    s={12} m={3}
                                    validate
                                    className="conEm"
                                    name="contacto_colonia"
                                    type="text"
                                    onChange={this.handleformEmer}
                                    placeholder="Colonia"
                                    value={this.state.form.contacto_colonia}

                                />
                              </Row>
                              <Row>
                                <Input
                                    s={12} m={3}
                                    validate
                                    className="conEm"
                                    name="contacto_municipio_delegacion"
                                    type="text"
                                    onChange={this.handleformEmer}
                                    placeholder="Municipio-Delegación"
                                    value={this.state.form.contacto_municipio_delegacion}
                                />
                                <Input
                                    className="conEm"
                                    s={11} m={3}
                                    name="contacto_codigo_postal"
                                    type="number"
                                    //max=" "
                                    onChange={this.handleformEmer}
                                    placeholder="*Código Postal"
                                    value={this.state.form.contacto_codigo_postal}
                                />
                                <Col s={12} m={3}>
                                  <select name="cat_nacion_id" className="browser-default conEm"
                                          onChange={(e) => this.handleformEmer(e)}>
                                    <option defaultValue={0}> País</option>
                                    {
                                      catNac
                                          ?
                                          catNac

                                              .map((el, i) => {
                                                return (
                                                    <option key={i}
                                                            value={el.objeto_id}> {el.descripcion_objeto} </option>
                                                )
                                              })
                                          : <option defaultValue={0}>Esatus</option>
                                    }
                                  </select>
                                </Col>
                                <Col s={12} m={3}>
                                  {
                                    this.state.form &&
                                    Number(this.state.form['cat_nacion_id']) === 186 ?
                                        <select name="cat_entidad_federativa_id" className="browser-default conEm"
                                                onChange={(e) => this.handleformEmer(e)}>
                                          <option defaultValue={0}> Entidad Federativa</option>
                                          {
                                            catEnt
                                                ?
                                                catEnt
                                                    .map((el, i) => {
                                                      return (
                                                          <option key={i}
                                                                  value={el.objeto_id}> {el.descripcion_objeto} </option>
                                                      )
                                                    })
                                                : <option defaultValue={0}>Esatus</option>
                                          }
                                        </select>
                                        :
                                        <span>

                                </span>
                                  }
                                </Col>
                              </Row>
                            </Fragment>

                      <div>
                        <Link  to="/homeAlum">
                        <Button>
                          Regresar
                        </Button>
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <Button
                            name="conEm"
                        >
                          Guardar y Continuar
                        </Button>
                      </div>
                    </form>
                  </>
            }
          </div>

          <div id="medicos" className="col s12">
             {
               staMedi
                 ?
                 <>
                   <TableMedStud
                     data={staMedi}
                   />
                 </>
                 :
                 <>
                   <form onSubmit={this.submitFormMedic}>
                     <Row className=" pt">
                       <Col
                           m={3}
                           s={12}
                       >
                         <select  name="cat_tipo_seguro_social_id"  className="browser-default medicos" onChange={(e)=>this.handleformMedic(e)}>
                           <option defaultValue={0} >Tipo Seguro Médico</option>
                           {
                             catSegSoci
                                 ?
                                 catSegSoci

                                     .map((el,i)=>{
                                       return(
                                           <option key={i}   value={el.objeto_id}  > {el.nombre_objeto} </option>
                                       )
                                     })
                                 : <option defaultValue={0} > Estatus</option>
                           }
                         </select>
                       </Col>
                     </Row>
                     <Row className=" pt">
                       {/*<Col
                           m={3}
                           s={12}
                       >
                         <select  name="alguna_alergia"  className="browser-default medicos" onChange={(e)=>this.handleformMedic(e)}>
                           <option defaultValue={0} >Padeces alguna alergia?</option>
                           <option value="si" > Si</option>
                           <option value="no" > No</option>
                         </select>
                       </Col>
                       <Col
                           m={12}
                           s={12}
                       >
                          <Input
                             s={12} m={12}
                             required
                             validate
                             className="medicos"
                             name="alergico_medicamento_alguna_alergia"
                             type="text"
                             onChange={(e)=>this.handleformMedic(e)}
                             label="Nombre o descripción corta de la alergia"
                         />
                       </Col>*/}
                     </Row>
                     <Row className=" pt">
                       <Col
                           m={3}
                           s={12}
                       >
                         <select  name="toma_medicamento"  className="browser-default medicos" onChange={(e)=>this.handleformMedic(e)}>
                           <option defaultValue={0} >¿Tomas algún medicamento?</option>
                           <option value="si" > Sí</option>
                           <option value="no" > No</option>
                         </select>
                       </Col>
                       <Col
                           m={12}
                           s={12}
                       >
                         {
                           this.state.form &&
                           this.state.form['toma_medicamento'] === 'si' ?
                               <Input
                                   s={12} m={12}
                                   required
                                   validate
                                   className="medicos"
                                   name="descripcion_toma_medicamento"
                                   type="text"
                                   onChange={(e)=>this.handleformMedic(e)}
                                   label="Nombre del o los medicamentos"
                               />
                               :
                               <span>
                        </span>
                         }
                       </Col>
                     </Row>
                     <Row className=" pt">
                       <Col
                           m={4}
                           s={12}
                       >
                         <select  name="tratamiento_medico"  className="browser-default medicos" onChange={(e)=>this.handleformMedic(e)}>
                           <option defaultValue={0} >¿Estás en algún tratamiento médico?</option>
                           <option value="si" > Sí</option>
                           <option value="no" > No</option>
                         </select>
                       </Col>
                       <Col
                           m={12}
                           s={12}
                       >
                         {
                           this.state.form &&
                           this.state.form['tratamiento_medico'] === 'si' ?
                               <Input
                                   s={12} m={12}
                                   required
                                   validate
                                   className="medicos"
                                   name="descripcion_tratamiento_medico"
                                   type="text"
                                   onChange={(e)=>this.handleformMedic(e)}
                                   label="Nombre o descripción corta del tratamiento"
                               />
                               :
                               <span>
                         </span>
                         }
                       </Col>
                     </Row>
                     <Row className=" pt">
                       <Col
                         m={4}
                         s={12}
                       >
                         <select  name="asistencia_capacidad_diferente"  className="browser-default medicos" onChange={(e)=>this.handleformMedic(e)}>
                           <option defaultValue={0} >¿Necesitas alguna asistencia especial?</option>
                           <option value="si" > Sí</option>
                           <option value="no" > No</option>
                         </select>
                       </Col>
                       <Col
                           m={12}
                           s={12}
                       >
                         {
                           this.state.form &&
                           this.state.form['asistencia_capacidad_diferente'] === 'si' ?
                         <Input
                             s={12} m={12}
                             required
                             validate
                             className="medicos"
                             name="descripcion_asistencia_capacidad_diferente"
                             type="text"
                             onChange={(e)=>this.handleformMedic(e)}
                             label="Nombre o descripción corta del tipo de asistencia"
                         />
                               :
                         <span>
                         </span>
                         }
                       </Col>
                     </Row>

                    <Row className=" pt">
                     <Col
                         m={5}
                         s={12}
                     >
                       <select  name="intervension_quirurgica_ultimos_2_anos"  className="browser-default medicos" onChange={(e)=>this.handleformMedic(e)}>
                         <option defaultValue={0} >¿Tuviste alguna operación recientemente (2 años)?</option>
                         <option value="si" > Sí</option>
                         <option value="no" > No</option>
                       </select>
                     </Col>
                      <Col
                          m={12}
                          s={12}
                      >
                        {
                          this.state.form &&
                          this.state.form['intervension_quirurgica_ultimos_2_anos'] === 'si' ?
                        <Input
                            s={12} m={12}
                            required
                            validate
                            className="medicos"
                            name="descripcion_intervension_quirurgica_ultimos_2_anos"
                            type="text"
                            onChange={(e)=>this.handleformMedic(e)}
                            label="Nombre o descripción corta de la operación"
                        />
                        :
                         <span>
                         </span>
                        }
                      </Col>
                   </Row>
                     <Link  to="/homeAlum">
                     <Button
                         name="regresar"
                     >
                       Regresar
                     </Button>
                     </Link>
                     &nbsp; &nbsp; &nbsp;
                     <Button
                       name="medicos"
                     >
                       Guardar y Regresar al menú
                     </Button>

                   </form>
                 </>
             }
           </div>
        </div>
      </>
    )
  }

}

export default UpdateProfileStud
import React from 'react'
import {Route, Switch} from "react-router-dom";
import Home from "./General/Home";
import Login from "./General/Login";
import HomeStud from "./Student/HomeStud";
import RegisterStud from "./Student/RegisterStud";
import UpdateOptionStud from "./Student/UpdateOptionStud";
import AddPhotoStud from "./Student/AddPhotoStud";
import AddDocStud from "./Student/AddDocStud";
import UpdateProfileStud from "./Student/UpdateProfileStud";
import HomeUA from "./UA/HomeUA";
import HomeCCA from "./CCA/HomeCCA";
import TableUA from "./UA/TableUA";
import ValidateRegister from './UA/ValidateRegister';
import ValidateRegisterCCA from "./CCA/ValidateRegisterCCA";
import StudentCCA from "./CCA/StudentCCA";
import PreregistrosCCA from "./CCA/PreregistrosCCA";
import SeleccionesCCA from "./CCA/SeleccionesCCA";
import AsignacionCCA from "./CCA/AsignacionCCA";
import SinAcceso from "./General/SinAcceso";
import Error404 from "./General/Error404";
import Preregister from "./General/Preregister";
import Pdf from "./Student/pdf";

const Router = () => {

  return(
    <Switch>
      <Route exact path = '/' component={Home}  />
      <Route exact path = '/login' component={Login}  />
      <Route exact path = '/homeAlum' component={HomeStud}  />
      <Route exact path = '/registroAlum' component={RegisterStud}  />
      <Route exact path = '/registroOption' component={UpdateOptionStud}  />
      <Route exact path = '/cambiarFotoAlum' component={AddPhotoStud}  />
      <Route exact path = '/agregarDocAlum' component={AddDocStud}  />
      <Route exact path = '/actualizaDatosAlum' component={UpdateProfileStud}  />
      <Route exact path = '/pdf' component={Pdf}  />
      <Route exact path = '/homeUA' component={HomeUA}  />
      <Route exact path = '/listUA' component={TableUA}  />
      <Route exact path = '/homeCCA' component={HomeCCA}  />
      <Route exact path = '/preregistrosCCA' component={PreregistrosCCA}  />
      <Route exact path = '/seleccionesCCA' component={SeleccionesCCA}  />
      <Route exact path = '/validarRegUA' component={ValidateRegister}  />
      <Route exact path = '/validarRegCCA' component={ValidateRegisterCCA} />
      <Route exact path = '/asignacionCCA' component={AsignacionCCA} />
      <Route exact path = '/sinAcceso' component={SinAcceso} />
      <Route exact path = '/studentIPNCCA' component={StudentCCA}  />
      <Route exact path = '/preRegister' component={Preregister}  />

      <Route component={Error404} />

    </Switch>
    )
};
export default Router
import React from 'react'
import {Button, Card, Table} from "react-materialize";
import {Link} from "react-router-dom";

const TableMedStud = ({data}) =>{
  return(
    <Card>
      <Table  responsive={true} className="white">
        <tbody>
        <tr>
          <td><strong>1.- Seguro Social  </strong> </td>
          <td> <strong>2.- Necesita asistencia especial </strong> </td>
          <td> <strong>3.- Descripcion de asistencia especial </strong> </td>
          <td> <strong>4.- Intervención quirúrgica últimos 2 años  </strong> </td>
          <td> <strong>5.- Descripción de la cirugía</strong> </td>
          <td> <strong>6.- Lleva tratamiento médico </strong> </td>
          <td> <strong>7.- Descripcion tratamiento médico </strong> </td>
          <td> <strong>8.- Usa Medicamentos</strong> </td>
          <td> <strong>9.- Descripción del medicamento</strong> </td>

        </tr>
        <tr>
          <td> {   data.cat_tipo_seguro_social_id } </td>
          <td> {   data.asistencia_capacidad_diferente } </td>
          <td> {   data.descripcion_asistencia_capacidad_diferente } </td>
          <td> {   data.intervension_quirurgica_ultimos_2_anos } </td>
          <td> {   data.descripcion_intervension_quirurgica_ultimos_2_anos } </td>
          <td> {   data.tratamiento_medico } </td>
          <td> {   data.descripcion_tratamiento_medico } </td>
          <td> {   data.toma_medicamento } </td>
          <td> {   data.descripcion_toma_medicamento } </td>

        </tr>
        </tbody>
      </Table>
      <Link  to="/homeAlum">
      <Button
          name="modificar"
      >        regresar
      </Button>
      </Link>
    </Card>
  )
};

export default TableMedStud